import HTTP from './../../Api/auth'
const state = {
  soustraitants: [],
  soustraitant: [],
  msgSuccessSousTraitant: "",
  msgErrorPrestataire: "",
  sousterrors:''
}

const getters = {
  soustraitants: state => state.soustraitants,
  soustraitant: state => state.soustraitant,
  msgSuccessSousTraitant: state => state.msgSuccessSousTraitant,
  msgErrorSousTraitant: state => state.msgErrorSousTraitant,
  sousterrors:state => state.sousterrors
}
const actions = {
  getSousTraitants({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL+"sous-traitants"+query).then((response) => {
      commit("setSousTraitants", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  listSousTraitants({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"sous-traitants-sans-pagination").then((response) => {
      commit("setSousTraitants", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  pageSousTraitants({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+data).then((response) => {
      commit("setSousTraitants", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.erreurs)
    })
  },
  getSousTraitant({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"sous-traitants/"+data.id).then((response) => {
      commit("setSousTraitant", response.data)
    }).catch((error) => {
      commit("setPregerrors", error.response.data.code_message)
    })
  },
  postSousTraitant({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"sous-traitants",data).then((response) => {
      commit("setMsgSuccessSousTraitant", response.data)
    }).catch((error) => {
      commit("setMsgErrorSousTraitant", error.response.data.code_message)
    })
  },
  putSousTraitant({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"sous-traitants/"+data.id,data).then((response) => {
      commit("setMsgSuccessSousTraitant", response.data)
    }).catch((error) => {
      commit("setMsgErrorSousTraitant", error.response.data.code_message)
    })
  },
  deleteSousTraitant({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"sous-traitants/"+data.id, data).then((response) => {
      commit("setMsgSuccessSousTraitant", response.data)
    }).catch((error) => {
      commit("setMsgErrorSousTraitant", error.response.data.code_message)
    })
  },

}
const mutations = {
  setSousTraitants: (state, soustraitants) => (
    state.soustraitants = soustraitants
  ),
  setSousTraitant: (state, soustraitant) => (
    state.soustraitant = soustraitant
  ),
  setMsgSuccessSousTraitant: (state, msgsuccesssoustraitants) => (
    state.msgSuccessSousTraitant = msgsuccesssoustraitants
  ),
  setMsgErrorSousTraitant: (state, msgerrorsoustraitants) => (
    state.msgErrorSousTraitant = msgerrorsoustraitants
  ),
  setPregerrors: (state, sousterrors) => (
    state.sousterrors = sousterrors
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
