import HTTP from './../../Api/auth'
const state = {
  rubriques: [],
  singleRubriques:'',
  delRubriques:'',
  modRubriques:'',
  ruberrors:'',
  singleActivites:'',
  modActivites:'',
  delActivites:'',
  acterrors:''
}

const getters = {
  rubriques: state => state.rubriques,
  singleRubriques: state => state.singleRubriques,
  delRubriques:state => state.delRubriques,
  modRubriques:state => state.modRubriques,
  ruberrors: state => state.ruberrors,
  singleActivites: state => state.singleActivites,
  delActivites:state => state.delActivites,
  modActivites:state => state.modActivites,
  acterrors: state => state.acterrors,
}

const actions = {
    Rubriques({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"rubriques").then((response) => {
      commit("setRubriques", response.data)
    }).catch((errors) => {
      commit("setErors", errors.response.data.code_message)
    })
  },
  saveRubriques({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"rubriques", data).then((response) => {
      commit("singleRubrique", {msg:'Rubriques a été créée avec succès.',data:response.data})
      commit("setErors", '')
    }).catch((errors) => {
      commit("singleRubrique",'')
      commit("setErors", errors.response.data.code_message)
    })
  },
  updateRubriques({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"rubriques/"+data.id, data).then((response) => {
      commit("modifierRubrique", {msg:'Rubriques a été modifiée avec succès.',data:response.data})
      commit("setErors", '')
    }).catch((errors) => {
      commit("modifierRubrique",'')
      commit("setErors", errors.response.data.code_message)
    })
  },
  deleteRubriques({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"rubriques/"+data.id).then((response) => {
      commit("supprimerRubrique", {msg:'Rubrique a été supprimée avec succès.',data:response.data})
      commit("setErors", '')
    }).catch((errors) => {
      commit("supprimerRubrique",'')
      commit("setErors", errors.response.data.code_message)
    })
  },
  saveActivites({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"rubriques/"+data.id+"/activites", data).then(() => {
      commit("singleActivite",  "Activité a été créée avec succès.")
      commit("setErors", '')
    }).catch((errors) => {
      commit("singleActivite",'')
      commit("setErrors", errors.response.data)
    })
  },
  updateActivites({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"rubriques/activites/"+data.id,data).then((response) => {
      commit("modifierActivite", {msg:'Activité a été modifiée avec succès.',data:response.data})
      commit("setErors", '')
    }).catch((errors) => {
      commit("modifierActivite",'')
      commit("setErors", errors.response.data.code_message)
    })
  },
  deleteActivites({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"rubriques/activites/"+data.id).then((response) => {
      commit("supprimerActivite", {msg:'Activité a été supprimée avec succès.',data:response.data})
      commit("setErors", '')
    }).catch((errors) => {
      commit("supprimerActivite",'')
      commit("setErors", errors.response.data.code_message)
    })
  },
}
const mutations = {
  setRubriques: (state, rubriques) => (
    state.rubriques = rubriques
  ),
  singleRubrique: (state, singlerubriques) => (
    state.singleRubriques = singlerubriques
  ),
  modifierRubrique: (state, modifierrubriques) => (
    state.modRubriques = modifierrubriques
  ),
  supprimerRubrique: (state, supprimerrubriques) => (
    state.delRubriques = supprimerrubriques
  ),
  setErors: (state, error) => (
    state.ruberrors = error
  ),
  singleActivite: (state, singleactivites) => (
    state.singleActivites = singleactivites
  ),
  modifierActivite: (state, modifieractivites) => (
    state.modActivites = modifieractivites
  ),
  supprimerActivite: (state, supprimeractivites) => (
    state.delActivites = supprimeractivites
  ),
  setErrors: (state, error) => (
    state.acterrors = error
  )
}

export default {
    state,
    getters,
    actions,
    mutations
}
