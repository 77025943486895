import Vue from "vue"
import Vuex from "vuex"
import AnalysesModule from "../store/modules/Analyse"
import AvenantsModule from "../store/modules/Avenant"
import AccordsFinancementModule from "../store/modules/AccordFinancement"
import AuditsModule from "../store/modules/Audit"
import AnalysesRecettesModule from "../store/modules/analyseRecettes"
import BusinessPlansModule from "../store/modules/BusinessPlans"
import BusinessPlanModule from "../store/modules/BusinessPlan"
import OrdreVirementsModule from "../store/modules/ordreVirements"
import CompteBancairesModule from "../store/modules/compteBancaires"
import ComptabilitesModule from "../store/modules/Comptabilite"
import TransactionsModule from "../store/modules/transactions"
import DecaissementsModule from "../store/modules/decaissements"
import DecomptesModule from "../store/modules/decomptes"
import DecomptePartielsModule from "../store/modules/decomptePartiels"
import DepartementsModule from "../store/modules/departements"
import IndicateursModule from "../store/modules/indicateurs"
import MarchesModule from "../store/modules/marches"
import UploadsModule from "../store/modules/uploads"
import UsersModule from "../store/modules/users-module"
import ProgrammesModule from "../store/modules/programmes"
import RubriquesModule from "../store/modules/rubriques"
import PrestatairesModule from "../store/modules/prestataires"
import SousTraitantsModule from "../store/modules/sous-traitants"
import AnalyseChargesModule from "./modules/AnalyseChargesVueGlobal"
import AnalyseChargesDecompteModule from "./modules/AnalyseChargesDecomptes"
//import ordreVirements from '../store/modules/ordreVirements'
import LeveeFondsModule from "../store/modules/leveefonds"
import ExerciceFiscauxModule from "../store/modules/exerciceFiscaux"
import MissionControlsModule from "../store/modules/missions"
import BanquesModule from "./modules/banque"
import ComptabiliteGlobalesModule from "./modules/ComptabiliteGblobale"
import PlanComptesModule from "./modules/PlanComptes"
import ControlGestionsModule from "./modules/ControlGestion"
import TresorerieModule from "./modules/Tresorerie"
import SuiviDesEmpruntsModule from "./modules/SuiviDesEmprunt"
import FiltreModule from "./modules/Filtre"
import SituationModule from "./modules/Situation"
import NotificationModule from "./modules/Notification"
import ExportationModule from "./modules/Exportation"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    AnalysesModule,
    AvenantsModule,
    AccordsFinancementModule,
    AuditsModule,
    AnalysesRecettesModule,
    BusinessPlansModule,
    BusinessPlanModule,
    OrdreVirementsModule,
    TransactionsModule,
    CompteBancairesModule,
    ComptabilitesModule,
    DecaissementsModule,
    DecomptesModule,
    DecomptePartielsModule,
    DepartementsModule,
    IndicateursModule,
    MarchesModule,
    UploadsModule,
    UsersModule,
    ProgrammesModule,
    RubriquesModule,
    PrestatairesModule,
    // ordreVirements,
    LeveeFondsModule,
    ExerciceFiscauxModule,
    AnalyseChargesModule,
    AnalyseChargesDecompteModule,
    SousTraitantsModule,
    MissionControlsModule,
    BanquesModule,
    ComptabiliteGlobalesModule,
    PlanComptesModule,
    ControlGestionsModule,
    TresorerieModule,
    SuiviDesEmpruntsModule,
    FiltreModule,
    SituationModule,
    NotificationModule,
    ExportationModule,
  }
})
