import HTTP from './../../Api/auth'
const state = {
  exportLink:'',
  failExportLink:'',
}

const getters = {
  exportLink: state => state.exportLink,
  failExportLink: state => state.failExportLink,
}

const actions = {
  exporterDonnees({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "impressions", data)
      .then((response) => {
        commit("setExportLink", response.data)
        commit("setFailExportLink", "")
      })
      .catch((error) => {
        commit("setExportLink", "")
        commit("setFailExportLink", error.response.data.code_message)
      })
  },
}

const mutations = {
  setExportLink: (state, files) => (
    state.exportLink = files
  ),
  setFailExportLink: (state, error) => (
    state.failExportLink = error
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
