<template>
  <div class="overlay"
       :class="this.notif.activated">
    <div class="form-message"
         :class="this.notif.activated + ' ' + this.notif.type">
      <p v-if="this.notif.type == 'toast-warning' || this.notif.type == 'toast-custom-warning'">&#9888; {{ this.notif.message }}</p>
      <p v-if="this.notif.type == 'toast-success' || this.notif.type == 'toast-custom-success'">&#10004; {{ this.notif.message }}</p>
      <p v-if="this.notif.type == 'toast-danger' || this.notif.type == 'toast-custom-danger'">&#10006; {{ this.notif.message }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Notif",
  props: ["notif"]
}
</script>
