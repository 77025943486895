import HTTP from './../../Api/auth'
const state = {
  programmes: [],
  singleProgrammes:'',
  progerrors:'',
  msgFailProgram:"",
  programme:[],
  progrubriques: [],
  assignProgramme: [],
  updateProgrammemsg:'',
  deleteProgrammemsg:'',
  programmeRecap:[],
  failProgrammeRecap:"",
}

const getters = {
  programmes: state => state.programmes,
  singleProgrammes: state => state.singleProgrammes,
  progerrors: state => state.progerrors,
  programme: state => state.programme,
  msgFailProgram: state => state.msgFailProgram,
  progrubriques: state => state.progrubriques,
  assignProgramme: state => state.assignProgramme,
  updateProgrammemsg: state => state.updateProgrammemsg,
  deleteProgrammemsg: state => state.deleteProgrammemsg,
  programmeRecap: state => state.programmeRecap,
  failProgrammeRecap: state => state.failProgrammeRecap,
}
const actions = {
  Programmes({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL+"programmes"+query).then((response) => {
      commit("setProgrammes", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  ListProgrammes({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL+"programmes-sans-pagination"+query).then((response) => {
      commit("setProgrammes", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  pageProgrammes({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+data).then((response) => {
      commit("setProgrammes", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  saveProgrammes({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"programmes", data).then(() => {
      commit("singleProgrames", 'le programme a bien été créé')
      commit("setMsgFailProgram", '')
    }).catch((error) => {
      commit("singleProgrames",'')
      commit("setMsgFailProgram", error.response.data.code_message)
    })
  },
  getProgramme({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"programmes/"+data).then((response) => {
      commit("setProgramme", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  getRubriques({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"rubriques").then((response) => {
      commit("setRubriques", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  updateProgrammes({commit},{data,pid}){
    HTTP.put(process.env.VUE_APP_AUTHURL+"programmes/"+pid,  data).then(() => {
      commit("updateProgramme", 'Le programme a bien été modifié')
      commit("setErors", '')
    }).catch((error) => {
      commit("updateProgramme",'')
      commit("setErors", error.response.data.erreurs)
    })
  },
  deleteProgrammes({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"programmes/"+data).then(() => {
      commit("deleteProgramme", 'Le programme a bien été supprimé')
      commit("setErors", '')
    }).catch((error) => {
      commit("deleteProgramme",'')
      commit("setErors", error.response.data.erreurs)
    })
  },
  importProgrammes({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"importer-programmes",data).then(()=>{
      commit("singleProgrames", 'Le fichier a bien été importé')
    }).catch((error)=>{
      commit("setErors", error.response.data.erreurs)
    })
  },
  getRecapProgramme({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"programmes/"+data+"/recap").then((response) => {
      commit("setProgrammeRecap", response.data.programme_decomptes[0])
    }).catch((error) => {
      commit("setFailProgrammeRecap", error.response.data.code_message)
    })
  },
}
const mutations = {
  setProgrammes: (state, programmes) => (
    state.programmes = programmes
  ),
  singleProgrames: (state, singleprogrammes) => (
    state.singleProgrammes = singleprogrammes
  ),
  updateProgramme: (state, updateprogrammes) => (
    state.updateProgrammemsg = updateprogrammes
  ),
  deleteProgramme: (state, deleteprogrammes) => (
    state.deleteProgrammemsg = deleteprogrammes
  ),
  setErors: (state, error) => (
    state.progerrors = error
  ),
  setMsgFailProgram: (state, msgfailprogram) => (
    state.msgFailProgram = msgfailprogram
  ),
  singleProgramme: (state, error) => (
    state.programme = error
  ),
  setRubriques: (state, rubriques) => (
    state.progrubriques = rubriques
  ),
  setProgramme: (state, programme) => (
    state.assignProgramme = programme
  ),
  setProgrammeRecap: (state, programme) => (
    state.programmeRecap = programme
  ),
  setFailProgrammeRecap: (state, programme) => (
    state.failProgrammeRecap = programme
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
