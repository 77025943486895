import HTTP from "../../Api/auth"


const state = {
    TableauDecomptePer:[],
    reportingDT:[],
    failReportingDT:[],
    reportingDecompte:[],
    failReportingDecompte:[],
    TableauDecompteSynthese:[],
    engagementFinancier:[],
    ReponseSucces:"",
    ReponseError:"",
    etatDecaissable:"",
    msgFailEtatDecaissable:"",
}
const getters ={
    TableauDecomptePer: state => state.TableauDecomptePer,
    reportingDT: state => state.reportingDT,
    failReportingDT: state => state.failReportingDT,
    reportingDecompte: state => state.reportingDecompte,
    failReportingDecompte: state => state.failReportingDecompte,
    TableauDecompteSynthese: state => state.TableauDecompteSynthese,
    engagementFinancier:state => state.engagementFinancier,
    ReponseSucces:state => state.ReponseSucces,
    ReponseError : state => state.ReponseError,
    etatDecaissable: state => state.etatDecaissable,
    msgFailEtatDecaissable: state => state.msgFailEtatDecaissable,
}
const actions ={
    getTableauDecompteSyntheseOld({commit},data){
        // HTTP.get(process.env.VUE_APP_AUTHURL+"finances/charges?type=ac_decomptes&annee="+data+"&synthese=1")
        //     .then(response =>{
        //         commit("setTableauDecompteSynthese",response.data)
        //     })
        //     .catch((error) =>{
        //         commit("setReponseError",error)
        //     })
        var query=""
        if (data!=null) {
          query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&charge_decompte="+data.charge
        }
        HTTP.get(process.env.VUE_APP_AUTHURL+"charges-decomptes-per"+query)
            .then(response =>{
                commit("setTableauDecompteSynthese",response.data)
            })
            .catch((error)=>{
                commit("setReponseError",error)
            })
    },
    getTableauDecompteSynthese({commit},data){
      var query=""
      if (data!=null) {
        query="?date_debut="+data.annee_debut+"&date_fin="+data.annee_fin
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"synthese-decomptes"+query)
          .then(response =>{
              commit("setTableauDecompteSynthese",response.data)
          })
          .catch((error)=>{
              commit("setReponseError",error)
          })
    },
    getTableauDecomptePer({commit},data){
        // HTTP.get(process.env.VUE_APP_AUTHURL+"finances/charges?type=ac_decomptes&annee="+data+"&synthese=0")
        //     .then(response =>{
        //         commit("setTableauDecomptePer",response.data)
        //     })
        //     .catch((error)=>{
        //         commit("setReponseError",error)
        //     })
        var query=""
        if (data!=null) {
          query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&charge_decompte="+data.charge
          if (data.id_programme) {
            query+="&id_programme="+data.id_programme
          }
        }
        HTTP.get(process.env.VUE_APP_AUTHURL+"charges-decomptes-per"+query,data.exportation)
            .then(response =>{
                commit("setTableauDecomptePer",response.data)
            })
            .catch((error)=>{
                commit("setReponseError",error)
            })
    },
    getEngagementFinancier({commit},data){
      var query=""
      if (data!=null) {
        query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"engagements-financiers"+query)
          .then(response =>{
              commit("setEngagementFinancier",response.data)
          })
          .catch((error)=>{
              commit("setReponseError",error)
          })
    },
    loadEtatDecaissables({commit},data){
      var query=""
      if (data!=null) {
        query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&periode="+data.periode+"&base="+data.base
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"etats-decaissables"+query)
          .then(response =>{
              commit("setEtatDecaissable",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailEtatDecaissable",error)
          })
    },
    getReportingDT({commit},data){
      var query=""
      if (data!=null) {
        query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&liste="+data.liste
        if (data.liste == "liste_decompte_marche" ) {
          query+="&id_marche="+data.id
        }else{
          query+="&id_entreprise="+data.id
        }
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"reporting-marches"+query)
          .then(response =>{
              commit("setReportingDT",response.data)
          })
          .catch((error)=>{
              commit("setFailReportingDT",error)
          })
  },
  getReportingDecompte({commit},data){
    var query=""
    if (data!=null) {
      if (data.etat==null) {
        query="?date_debut="+data.annee_debut+"&date_fin="+data.annee_fin
      } else {
        query="?date_debut="+data.annee_debut+"&date_fin="+data.annee_fin+"&etat="+data.etat
        
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL+"reporting-decomptes"+query)
        .then(response =>{
            commit("setReportingDecompte",response.data)
        })
        .catch((error)=>{
            commit("setFailReportingDecompte",error)
        })
  },
}
const mutations={
    setTableauDecomptePer :(state,DonneesRecu) =>(state.TableauDecomptePer = DonneesRecu),
    setReportingDT :(state,DonneesRecu) =>(state.reportingDT = DonneesRecu),
    setFailReportingDT :(state,DonneesRecu) =>(state.FailReportingDT = DonneesRecu),
    setReportingDecompte :(state,DonneesRecu) =>(state.reportingDecompte = DonneesRecu),
    setFailReportingDecompte :(state,DonneesRecu) =>(state.FailReportingDecompte = DonneesRecu),
    setTableauDecompteSynthese:(state,DonneesRecu) =>(state.TableauDecompteSynthese = DonneesRecu),
    setEngagementFinancier:(state,donneesRecu) => (state.engagementFinancier =donneesRecu),
    setReponseSucces:(state,reponseRecu) => (state.ReponseSucces =reponseRecu),
    setReponseError:(state,reponseRecu) =>(state.ReponseError=reponseRecu),
    setEtatDecaissable:(state,reponseRecu) =>(state.etatDecaissable=reponseRecu),
    setMsgFailEtatDecaissable:(state,reponseRecu) =>(state.msgFailEtatDecaissable=reponseRecu),
}

export default {
    state,
    getters,
    actions,
    mutations
}