import HTTP from './../../Api/auth'
const state = {
  audits: [],
  singleAudit:'',
  auditsErrors:'',
  singleAuditErrors:'',
}

const getters = {
  audits: state => state.audits,
  singleAudit:state => state.singleAudit,
  auditsErrors:state => state.auditsErrors,
  singleAuditErrors:state => state.singleAuditErrors,
}

const actions = {
  Audits({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"audits").then((response) => {
      commit("setAudits", response.data)
    }).catch((errors) => {
      commit("setAuditsErrors", errors)
    })
  },
  getAudit({commit},id){
    HTTP.get(process.env.VUE_APP_AUTHURL+"audits/"+id).then((response) => {
      commit("setSingleAudit", response.data)
    }).catch((errors) => {
      commit("setSingleAuditErrors", errors)
    })
  },
  pageAudits({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+data).then((response) => {
      commit("setAudits", response.data)
    }).catch((error) => {
      commit("setAuditsErrors", error.response.data.code_message)
    })
  },
}
const mutations = {
  setAudits: (state, audits) => (
    state.audits = audits
  ),
  setSingleAudit: (state, singleaudits) => (
    state.singleAudit = singleaudits
  ),
  setAuditsErrors: (state, error) => (
    state.auditsErrors = error
  ),
  setSingleAuditErrors: (state, error) => (
    state.singleAuditErrors = error
  )
}

export default {
    state,
    getters,
    actions,
    mutations
}
