import HTTP from "./../../Api/auth"

const state = {
  donneesFiscaux: [],
  exerciceFiscal: [],
  successGet: "",
  errorsGet: "",
  successPost: "",
  errorsPost: ""
}

const getters = {
  donneesFiscaux: state => state.donneesFiscaux,
  exerciceFiscal: state => state.exerciceFiscal,
  successGet: state => state.successGet,
  errorsGet: state => state.errorsGet,
  successPost: state => state.successPost,
  errorsPost: state => state.errorsPost
}

const actions = {
  getDonnesFiscaux({ commit }, data) {
    let query=""
    if (data!=null) {
      query="?libelle="+data.libelle+"&order="+data.order+"&tri="+data.tri  
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "exercices-fiscaux" + query)
      .then(respnse => {
        commit("setDonneesFiscaux", respnse.data)
        commit("setSuccessGet", "Donnees recu avec succes")
        commit("setErrorGet", "")
      })
      .catch(errors => {
        commit("setDonneesFiscaux", "")
        commit("setErrorGet", errors)
        commit("setSuccessGet", "")
      })
  },
  getExerciceFiscal({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "exercices-fiscaux/" + data)
      .then(response => {
        commit("setExerciceFiscal", response.data)
        commit("setErrorGet", "")
      })
      .catch(errors => {
        commit("setErrorGet", errors)
        commit("setSuccessGet", "")
      })
  },
  pageEFiscaux({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setDonneesFiscaux", response.data)
      })
      .catch(errors => {
        commit("setErrorGet", errors)
      })
  },
  saveEFiscaux({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "exercices-fiscaux", data)
      .then(response => {
        commit("setsuccessPost", "Exercice fiscale creer avec succes", response.data.id)
        commit("setErrorPost", "")
      })
      .catch(error => {
        commit("setsuccessPost", "")
        commit("setErrorPost", error)
      })
  }
}

const mutations = {
  setExerciceFiscal: (state, donneesRecus) => (state.exerciceFiscal = donneesRecus),
  setDonneesFiscaux: (state, donneesRecus) => (state.donneesFiscaux = donneesRecus),
  setSuccessGet: (state, reponseSucces) => (state.setSuccessGet = reponseSucces),
  setErrorGet: (state, reponseError) => (state.errorsGet = reponseError),
  setsuccessPost: (state, reponsePost) => (state.successPost = reponsePost),
  setErrorPost: (state, reponsePost) => (state.errorsPost = reponsePost)
}

export default {
  state,
  getters,
  actions,
  mutations
}
