<template>
  <div id="app"
       :style="'height:' + wnd + 'px;'">
    <div v-if="setSession()">
      <RwvHeaderLogin />
      <Notif :notif="notif" />
      <div class="main_sec"
           v-bind:class="menuShrink">
        <RwvSidebar @shrink="receive" />
        <router-view />
      </div>
    </div>
    <div v-else>
      <RwvHeader />
      <router-view />
    </div>
  </div>
</template>
<style>
.main_sec {
  background-image: url("/assets/logo.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  
}
.main_sec.menu_mobil .aside_menu {
  left: 0;
  overflow: visible !important;
}
.main_sec.menu_shrink .main_container {
  max-width: calc(100% - 303px);
}
@media only screen and (max-width: 768px) {
  .main_sec.menu_shrink .main_container {
    max-width: 100%;
  }
}
</style>
<script>
import RwvHeaderLogin from "@/components/TheHeaderLogin"
import RwvSidebar from "@/components/TheSidebar"
import RwvHeader from "@/components/TheHeader"
import Notif from "@/components/shared/Toast"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "App",
  components: {
    RwvHeaderLogin,
    RwvSidebar,
    Notif,
    RwvHeader
  },
  data: () => ({
    menuShrink: "",
    currenturl: {},
    checker:"",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    wnd: window.innerHeight
  }),
  created() {
    this.init()
    setInterval(() => {
      if (this.$route.name !="Login" && this.$route.name !="ForgotPassword" && this.$route.name !="ResetPassword") {
        var user =this.setSession()
        // console.log("reloader")
        // console.log(user)
        if (!user) {
          this.notif.message = "Votre session a expiré. Veuillez vous reconnecter."
          this.notif.type = "toast-warning"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
              window.location.href="/"
            }.bind(this),
            3000
          )  
        }
      }
    },1000)
  },
  computed: {
    ...mapGetters(["usersDetails"])
  },
  methods: {
    ...mapActions(["getToken"]),
    receive(childData) {
      this.menuShrink = childData
    },
    init() {
      if (localStorage.getItem("token") === null) {
        this.getToken()
      }
    },
    setSession() {
      return localStorage.getItem("user")
    }
  }
}
</script>
