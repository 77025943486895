import HTTP from './../../Api/auth'
const state = {
  departements: [],
  singleProgrammes:'',
  progerrors:'',
  programme:[],
  progrubriques: []
}

const getters = {
  departements: state => state.departements,
}
const actions = {
  getDepartements({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"departements").then((response) => {
      commit("setDepartements", response.data)
    }).catch((errors) => {
      commit("setErors", errors.response.data.code_message)
    })
  }
}
const mutations = {
  setDepartements: (state, departements) => (
    state.departements = departements
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
