import HTTP from "./../../Api/auth"
const state = {
  indicateurs1: [],
  inderrors1: "",
  indicateurs2: [],
  inderrors2: "",
  indicateurs3: [],
  inderrors3: "",
  indicateurs4: [],
  inderrors4: "",
  indicateurs5: [],
  inderrors5: "",
  indicateurs6: [],
  inderrors6: "",
  indicateurs7: [],
  inderrors7: "",
  indicateurs8: [],
  inderrors8: "",
  indicateurs9: [],
  inderrors9: "",
  indicateurs10: [],
  inderrors10: "",
}

const getters = {
  indicateurs1: state => state.indicateurs1,
  inderrors1: state => state.inderrors1,
  indicateurs2: state => state.indicateurs2,
  inderrors2: state => state.inderrors2,
  indicateurs3: state => state.indicateurs3,
  inderrors3: state => state.inderrors3,
  indicateurs4: state => state.indicateurs4,
  inderrors4: state => state.inderrors4,
  indicateurs5: state => state.indicateurs5,
  inderrors5: state => state.inderrors5,
  indicateurs6: state => state.indicateurs6,
  inderrors6: state => state.inderrors6,
  indicateurs7: state => state.indicateurs7,
  inderrors7: state => state.inderrors7,
  indicateurs8: state => state.indicateurs8,
  inderrors8: state => state.inderrors8,
  indicateurs9: state => state.indicateurs9,
  inderrors9: state => state.inderrors9,
  indicateurs10: state => state.indicateurs10,
  inderrors10: state => state.inderrors10,
}

const actions = {
  async loadIndicateurs1({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs1", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError1", errors.response.data.code_message)
      })
  },
  async loadIndicateurs2({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs2", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError2", errors.response.data.code_message)
      })
  },
  async loadIndicateurs3({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs3", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError3", errors.response.data.code_message)
      })
  },
  async loadIndicateurs4({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs4", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError4", errors.response.data.code_message)
      })
  },
  async loadIndicateurs5({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs5", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError5", errors.response.data.code_message)
      })
  },
  async loadIndicateurs6({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs6", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError6", errors.response.data.code_message)
      })
  },
  async loadIndicateurs7({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs7", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError7", errors.response.data.code_message)
      })
  },
  async loadIndicateurs8({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs8", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError8", errors.response.data.code_message)
      })
  },
  async loadIndicateurs9({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs9", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError9", errors.response.data.code_message)
      })
  },
  async loadIndicateurs10({ commit }, data) {
    let query = "?annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&type=" + data.type
    await HTTP.get(process.env.VUE_APP_AUTHURL + "indicateurs/"+data.indicateur+ query)
      .then(response => {
        commit("setIndicateurs10", response.data.valeur)
      })
      .catch(errors => {
        commit("setIndError10", errors.response.data.code_message)
      })
  }

}
const mutations = {
  setIndicateurs1: (state, indicateurs1) => (state.indicateurs1 = indicateurs1),
  setIndError1: (state, error) => (state.inderrors1 = error),
  setIndicateurs2: (state, indicateurs2) => (state.indicateurs2 = indicateurs2),
  setIndError2: (state, error) => (state.inderrors2 = error),
  setIndicateurs3: (state, indicateurs3) => (state.indicateurs3 = indicateurs3),
  setIndError3: (state, error) => (state.inderrors3 = error),
  setIndicateurs4: (state, indicateurs4) => (state.indicateurs4 = indicateurs4),
  setIndError4: (state, error) => (state.inderrors4 = error),
  setIndicateurs5: (state, indicateurs5) => (state.indicateurs5 = indicateurs5),
  setIndError5: (state, error) => (state.inderrors5 = error),
  setIndicateurs6: (state, indicateurs6) => (state.indicateurs6 = indicateurs6),
  setIndError6: (state, error) => (state.inderrors6 = error),
  setIndicateurs7: (state, indicateurs7) => (state.indicateurs7 = indicateurs7),
  setIndError7: (state, error) => (state.inderrors7 = error),
  setIndicateurs8: (state, indicateurs8) => (state.indicateurs8 = indicateurs8),
  setIndError8: (state, error) => (state.inderrors8 = error),
  setIndicateurs9: (state, indicateurs9) => (state.indicateurs9 = indicateurs9),
  setIndError9: (state, error) => (state.inderrors9 = error),
  setIndicateurs10: (state, indicateurs10) => (state.indicateurs10 = indicateurs10),
  setIndError10: (state, error) => (state.inderrors10 = error),
}

export default {
  state,
  getters,
  actions,
  mutations
}
