import HTTP from "./../../Api/auth"
const state = {
  decomptes: [],
  decompterrors: "",
  successdecompte: "",
  validDecomptes: [],
  decaissDecomptes: [],
  failDecaissDecomptes: [],
  decerrors: "",
  decompte: [],
  dcmpsous_traitants: [],
  assignDecompte: [],
  updateDecomptemsg: "",
  etatSuppressionDecompte:"",
  situationMarche:"",
  msgFailSituationMarche:"",
  conformiteDecompte:"",
  msgFailConformiteDecompte:"",
  listConformiteDecompte:"",
  msgFailListConformiteDecompte:"",
  soumissionDecompte:"",
  msgFailSoumissionDecompte:"",
  restePayer:"",
  msgFailRestePayer:"",
  tableauCommentaires: [],
  decompteEnAttente:""
}

const getters = {
  decomptes: state => state.decomptes,
  decompterrors: state => state.decompterrors,
  successdecompte: state => state.successdecompte,
  validDecomptes: state => state.validDecomptes,
  decaissDecomptes: state => state.decaissDecomptes,
  failDecaissDecomptes: state => state.failDecaissDecomptes,
  decerrors: state => state.progerrors,
  decompte: state => state.decompte,
  dcmpsous_traitants: state => state.dcmpsous_traitants,
  assignDecompte: state => state.assignDecompte,
  updateDecomptemsg: state => state.updateDecomptemsg,
  gettableauCommentaires: state => state.tableauCommentaires,
  situationMarche: state => state.situationMarche,
  msgFailSituationMarche: state => state.msgFailSituationMarche,
  conformiteDecompte: state => state.conformiteDecompte,
  msgFailConformiteDecompte: state => state.msgFailConformiteDecompte,
  listConformiteDecompte: state => state.listConformiteDecompte,
  msgFailListConformiteDecompte: state => state.msgFailListConformiteDecompte,
  soumissionDecompte: state => state.soumissionDecompte,
  msgFailSoumissionDecompte: state => state.msgFailSoumissionDecompte,
  restePayer: state=>state.restePayer,
  decompteEnAttente: state=>state.decompteEnAttente,
  msgFailRestePayer: state=>state.msgFailRestePayer,
  getEtatSuppressionDecompte: state => state.etatSuppressionDecompte
}

const actions = {
  Decomptes({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?groupe="+data.groupe+"&filtres=[" //"&limite="+data.limite+  
      data.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes" + query)
      .then(response => {
        commit("setDecomptes", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  pageDecomptes({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setDecomptes", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  DecomptesSansPagination({ commit }) {
    HTTP.get(process.env.VUE_APP_AUTHURL +"decomptes-sans-pagination")
      .then(response => {
        commit("setDecomptes", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  getDecomptes({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes" + query)
      .then(response => {
        commit("setDecomptes", response.data)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  DecomptesValider({ commit }, data) {
    let query = ""
    var count=1
    var used=0
    if (data != null) {
      if (data.en_attente != null && data.en_attente != "" ) {
        query = "?order=" + data.order + "&en_attente=" + data.en_attente 
      } else {
        if (data.valide != null && data.valide != "") {
          query = "?order=" + data.order + "&valide=" + data.valide 
        } else {
          if (data.refuse != null && data.refuse != "") {
            query = "?order=" + data.order + "&refuse=" + data.refuse 
          }
        }
      }
      if (data.filtre) {
        query+="&filtres=["  
        data.filtre.forEach(filtre => {
          if (filtre.type == "date") {
            if (filtre.operation!="" && filtre.valeur!="") {
              if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }else{
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }
            }
          }else{
            if (filtre.valeur!="" && filtre.nature!="") {
              if (count>1 && used !=0) {
                query+=","
                console.log(count)
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
              used+=1
            }
          }
          count+=1
        })
        query+="]"
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes" + query)
      .then(response => {
        commit("setValidDecomptes", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  DecomptesEnAttente({ commit }) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decompte-attente-paiement")
      .then(response => {
        commit("setDecompteEnAttente", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  DecomptesDecaisser({ commit }, data) {
    let query = ""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes-valide" + query)
      .then(response => {
        commit("setDecaissDecomptes", response.data)
      })
      .catch((error) => {
        commit("setFailDecaissDecomptes", error.response.data.code_message)
      })
  },
  saveDecomptes({ commit }, data) {
    let query = ""
    if (data.partiel) {
      query = "?partiel=" + data.partiel + "&id=" + data.id
    }
    HTTP.post(process.env.VUE_APP_AUTHURL + "decomptes" + query, data)
      .then(() => {
        commit("setSuccess", "Le décompte a été créé avec succès")
        commit("setError", "")
      })
      .catch((error) => {
        commit("setSuccess", "")
        commit("setError", error.response.data.code_message)
      })
  },
  getDecompte({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes/" + data)
      .then(response => {
        commit("setDecompte", response.data.decompte)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  async getPrestataire({ commit }) {
    await HTTP.get(process.env.VUE_APP_AUTHURL + "sous-traitants")
      .then(response => {
        commit("setSous_traitants", response.data)
      })
      .catch((error) => {
        commit("setError", error.response.data.code_message)
      })
  },
  async updateDecomptes({ commit }, { data, pid }) {
    await HTTP.put(process.env.VUE_APP_AUTHURL + "decomptes/" + pid, data)
      .then(() => {
        commit("updateDecompte", "le Décompte a été mise à jour")
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  actionValidateDecompte({ commit }, { data, id }) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "decomptes/" + id + "/valider", data)
      .then(response => {
        commit("updateDecompte", response.data.code_message)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  actionGetCommentaires({ commit }, id) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes/" + id + "/commentaires")
      .then(response => {
        commit("setTableauCommentaire", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  resteAPayer({ commit }, id) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes-reste-a-payer/" + id )
      .then(response => {
        commit("setRestePayer", response.data.reste_a_payer)
      })
      .catch((error) => {
        commit("setMsgFailRestePayer", error.response.data.code_message)
      })
  },
  actionPostCommentaire({ commit }, { data, id }) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "decomptes/" + id + "/commentaires", data)
      .then(response => {
        commit("setSuccess", response.data)
      })
      .catch((error) => {
        commit("setError", error.response.data.code_message)
      })
  },
  actionSuppresionDecompte({commit},id){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"decomptes/"+id)
        .then(response => {
          commit("setEtatSuppressionDecompte",response.status)
        })
        .catch((error) =>{
          commit("setEtatSuppressionDecompte",error.response.status)
        })

  },
  situationMarcheDecompte({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-financiere/"+data.id_marche+"?id_ordonnancement="+data.id_ordonnancement)
        .then(response => {
          commit("setSituationMarche",response.data)
        })
        .catch((error) =>{
          commit("setMsgFailSituationMarche",error.response.data)
        })
  },
  importDecompte({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-decomptes", data)
      .then(() => {
        commit("setSuccess", "Le fichier a bien été importé")
      })
      .catch((error) => {
        commit("setError", error.response.data.erreurs)
      })
  },
  imputerDecompte({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "imputations", data)
      .then(() => {
        commit("setSuccess", "Le décompte a été imputé avec succès")
      })
      .catch((error) => {
        commit("setError", error.response.data.erreurs)
      })
  },
  soumettreDecompte({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "verifications", data)
      .then(() => {
        commit("setSoumissionDecompte", "Le décompte a été soumis avec succès")
      })
      .catch((error) => {
        commit("setMsgFailConformiteDecompte", error.response.data.erreurs)
      })
  },
  conformitesDecomptes({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "decomptes/"+data.decompte+"/conformites", data)
      .then(() => {
        commit("setConformiteDecompte", "Les conformités ont été validé.")
      })
      .catch((error) => {
        commit("setMsgFailConformiteDecompte", error.response.data.erreurs)
      })
  },
  getConformitesDecomptes({commit}, data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes/"+data+"/conformites")
      .then((response) => {
        commit("setListConformiteDecompte", response.data)
      })
      .catch((error) => {
        commit("setMsgFailListConformiteDecompte", error.response.data.erreurs)
      })
  }
}
const mutations = {
  setDecomptes: (state, decompte) => (state.decomptes = decompte),
  setSuccess: (state, successMsg) => (state.successdecompte = successMsg),
  setValidDecomptes: (state, validDecomptes) => (state.validDecomptes = validDecomptes),
  setDecaissDecomptes: (state, validDecomptes) => (state.decaissDecomptes = validDecomptes),
  setFailDecaissDecomptes: (state, validDecomptes) => (state.failDecaissDecomptes = validDecomptes),
  updateDecompte: (state, updatedecomptes) => (state.updateDecomptemsg = updatedecomptes),
  setError: (state, error) => (state.decompterrors = error),
  setErrors: (state, error) => (state.decerrors = error),
  setSous_traitants: (state, sous_traitants) => (state.dcmpsous_traitants = sous_traitants),
  setDecompte: (state, decompte) => (state.assignDecompte = decompte),
  setTableauCommentaire: (state, donneesRecu) => (state.tableauCommentaires = donneesRecu),
  setSituationMarche: (state, donneesRecu) => (state.situationMarche = donneesRecu),
  setMsgFailSituationMarche:(state,reponse) => (state.msgFailSituationMarche=reponse),
  setConformiteDecompte: (state, donneesRecu) => (state.conformiteDecompte = donneesRecu),
  setMsgFailConformiteDecompte:(state,reponse) => (state.msgFailConformiteDecompte=reponse),
  setListConformiteDecompte: (state, donneesRecu) => (state.listConformiteDecompte = donneesRecu),
  setMsgFailListConformiteDecompte:(state,reponse) => (state.msgFailListConformiteDecompte=reponse),
  setSoumissionDecompte: (state, donneesRecu) => (state.soumissionDecompte = donneesRecu),
  setMsgFailSoumissionDecompte:(state,reponse) => (state.msgFailSoumissionDecompte=reponse),
  setRestePayer: (state, donneesRecu) => (state.restePayer = donneesRecu),
  setDecompteEnAttente: (state, donneesRecu) => (state.decompteEnAttente = donneesRecu),
  setMsgFailRestePayer:(state,reponse) => (state.msgFailRestePayer=reponse)
}

export default {
  state,
  getters,
  actions,
  mutations
}
