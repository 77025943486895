import HTTP from "../../Api/auth"

const state ={
    TableauCharges:[],
    TableauGraphique:[],
    succesTableauCharges:'',
    errorTableauCharges:''
}

const getters ={
    TableauCharges: state => state.TableauCharges,
    TableauGraphique: state => state.TableauGraphique,
    succesTableauCharges: state => state.succesTableauCharges,
    errorTableauCharges :state => state.errorTableauCharges
}

const actions ={
    getTableauCharges({commit},data){
        // HTTP.get(process.env.VUE_APP_AUTHURL+'finances/charges?type=ac_recapitulatif&annee='+data+'&format=TABLEAU')
        //     .then(response =>{
        //         commit("setTableauCharges",response.data)
        //     })
        //     .catch((error) =>{
        //         commit("seterrorTableauCharges",error)
        //     })
        HTTP.get(process.env.VUE_APP_AUTHURL+'charges-vue-global?annee_debut='+data.annee_debut+'&annee_fin='+data.annee_fin+'&analyse=TABLEAU')
        .then(response =>{
            commit("setTableauCharges",response.data)
        })
        .catch((error) =>{
            commit("seterrorTableauCharges",error)
        })
    },
    getTableauGraphique({commit},data){
        // HTTP.get(process.env.VUE_APP_AUTHURL+'finances/charges?type=ac_recapitulatif&annee='+data+'&format=DIAGRAMME')
        //     .then(response =>{
        //         commit("setTableauGraphique",response.data)
        //     })
        //     .catch((error) =>{
        //         commit("seterrorTableauCharges",error)
        //     })
        HTTP.get(process.env.VUE_APP_AUTHURL+'charges-vue-global?annee_debut='+data.annee_debut+'&annee_fin='+data.annee_fin+'&analyse=GRAPHIQUE')
        .then(response =>{
            commit("setTableauGraphique",response.data)
        })
        .catch((error) =>{
            commit("seterrorTableauCharges",error)
        })
    }
}

const mutations ={
    setTableauCharges : (state,TableauChargesRecu) => (state.TableauCharges=TableauChargesRecu),
    setsuccesTableauCharges:(state,ReponseTableauCharges) => (state.succesTableauCharges=ReponseTableauCharges),
    seterrorTableauCharges :(state,ReponseTableauCharges) => (state.errorTableauCharges = ReponseTableauCharges),
    setTableauGraphique:(state,TableauGraphiqueRecu) =>(state.TableauGraphique = TableauGraphiqueRecu)
}

export default{
    state,
    getters,
    actions,
    mutations
}