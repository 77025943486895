/**
 * @description      : 
 * @author           : AbigaelHOMENYA
 * @group            : 
 * @created          : 04/12/2022 - 14:25:52
 * 
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/12/2022
 * - Author          : AbigaelHOMENYA
 * - Modification    : 
 **/
import HTTP from "../../Api/auth"

const state = {
    arLevee: [],
    arRessource: [],
    arRessourcev: [],
    arRecetteG: [],
    arRecetteA: [],
    arRecetteS: [],
    arRecetteT: [],
    arRecetteM: [],
    arRecette: [],
    arRedevance: [],
    arRedevanceV: [],
    errorLevee: "",
    errorRessource: "",
    ERedevance: "",
    recettePeage: "",
    msgFailRecettePeage: "",
    recettePeageG: "",
    recettePesageG: "",
    recettePeageA: "",
    recettePeageS: "",
    recettePeageT: "",
    recettePeageM: "",
    msgFailRecettePeageG: "",
    redevancePesage: "",
    msgFailRedevancePesage: "",
    ressourcesAffectees: "",
    ressourcesAireStationnement: "",
    msgFailRessourcesAffectees: "",
    msgFailRessourcesAireStationnement: "",
    etatRessources: "",
    msgFailEtatRessources: "",
    msgSuccessImportPeagePesage: "",
    msgFailImportPeagePesage: "",
}

const getters = {
    arLevee: state => state.arLevee,
    arRessource: state => state.arRessource,
    arRessourcev: state => state.arRessourcev,
    arRecetteG: state => state.arRecetteG,
    ArRecetteA: state => state.ArRecetteA,
    ArRecetteS: state => state.ArRecetteS,
    ArRecetteT: state => state.ArRecetteT,
    ArRecetteM: state => state.ArRecetteM,
    arRecette: state => state.arRecette,
    arRedevance: state => state.arRedevance,
    arRedevanceV: state => state.arRedevanceV,
    errorLevee: state => state.errorLevee,
    errorRessource: state => state.errorRessource,
    ERedevance: state => state.ERedevance,
    recettePeage: state => state.recettePeage,
    msgFailRecettePeage: state => state.msgFailRecettePeage,
    recettePeageG: state => state.recettePeageG,
    recettePesageG: state => state.recettePesageG,
    recettePeageA: state => state.recettePeageA,
    recettePeageS: state => state.recettePeageS,
    recettePeageT: state => state.recettePeageT,
    recettePeageM: state => state.recettePeageM,
    msgFailRecettePeageG: state => state.msgFailRecettePeageG,
    redevancePesage: state => state.redevancePesage,
    msgFailRedevancePesage: state => state.msgFailRedevancePesage,
    ressourcesAffectees: state => state.ressourcesAffectees,
    ressourcesAireStationnement: state => state.ressourcesAireStationnement,
    msgFailRessourcesAffectees: state => state.msgFailRessourcesAffectees,
    msgFailRessourcesAireStationnement: state => state.msgFailRessourcesAireStationnement,
    etatRessources: state => state.etatRessources,
    msgFailEtatRessources: state => state.msgFailEtatRessources,
    msgSuccessImportPeagePesage: state => state.msgSuccessImportPeagePesage,
    msgFailImportPeagePesage: state => state.msgFailImportPeagePesage,
}

const actions = {
    loadRecetteLeveeFnd({ commit }, data) {
      var query=""
      var count=1
      var used=0
      if (data.filtre) {
        query+="&filtres=["  
        data.filtre.forEach(filtre => {
          if (filtre.type == "date") {
            if (filtre.operation!="" && filtre.valeur!="") {
              if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }else{
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }
            }
          }else{
            if (filtre.valeur!="" && filtre.nature!="") {
              if (count>1 && used !=0) {
                query+=","
                console.log(count)
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'",  "valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
              used+=1
            }
          }
          count+=1
        })
        query+="]"
      }
      HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-levees-fonds?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&banque=" + data.banque + query)
      .then(response => {
          commit("setArLevee", response.data.donnees)
      })
      .catch(error => {
          commit("setELevee", error)
              //console.log(error)
      })  
      // HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_levee_fonds&banque=" + data)
        //     .then(response => {
        //         commit("setArLevee", response.data.donnees)
        //     })
        //     .catch(error => {
        //         commit("setELevee", error)
        //             //console.log(error)
        //     })
    },
    loadRessourceAf({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_ressources_affectees&annee=" + data + "&variation=0")
            .then(response => {
                commit("setArRessource", response.data.donnees)
            })
            .catch(error => {
                commit("setERessource", error)
                    //console.log(error)
            })
    },
    loadRessourceAfv({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_ressources_affectees&annee=" + data + "&variation=1")
            .then(response => {
                commit("setArRessourcev", response.data.donnees)
            })
            .catch(error => {
                commit("setERessource", error)
                    //console.log(error)
            })
    },
    loadRecettePeageG({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data + "&gare=TOUT")
            .then(response => {
                commit("setArRecetteG", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    loadRecettePeageA({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data + "&gare=TOUT")
            .then(response => {
                commit("setArRecetteA", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    loadRecettePeageS({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data + "&gare=TOUT")
            .then(response => {
                commit("setArRecetteS", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    loadRecettePeageT({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data + "&gare=TOUT")
            .then(response => {
                commit("setArRecetteT", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    loadRecettePeageM({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data + "&gare=TOUT")
            .then(response => {
                commit("setArRecetteM", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    loadRecettePeage({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_recettes_peage&annee=" + data.year + "&gare=" + data.gare)
            .then(response => {
                commit("setArRecette", response.data.donnees)
            })
            .catch(error => {
                commit("setERecette", error)
                    //console.log(error)
            })
    },
    RecettePeage({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin 
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages" + query)
            .then(response => {
                commit("setRecettePeage", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePeage", error.response.data.code_message)
                    //console.log(error)
            })
    },

    RecettePesageG({ commit }, data) {
        var query = ""
        if (data != null) {
          if (data.axe==null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=" + data.gare
          } else {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&axe=" + data.axe 
          }
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "redevances-pesages-gare" + query)
            .then(response => {
                commit("setRecettePesageG", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePesageG", error.response.data.code_message)
                    //console.log(error)
            })
    },
    RecettePeageG({ commit }, data) {
      var query = ""
      if (data != null) {
          if(data.axe==null){
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=" + data.gare
          }else{
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&axe=" + data.axe
          }
      }
      HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages-gare" + query)
          .then(response => {
              commit("setRecettePeageG", response.data.donnees)
          })
          .catch(error => {
              commit("setMsgFailRecettePeageG", error.response.data.code_message)
                  //console.log(error)
          })
    },
    // Debut des recettes par gare
    RecettePeageAttingue({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=Attingue"
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages-gare" + query)
            .then(response => {
                commit("setRecettePeageA", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePeageA", error.response.data.code_message)
                    //console.log(error)
            })
    },

    RecettePeageSingrobo({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=Singrobo"
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages-gare" + query)
            .then(response => {
                commit("setRecettePeageS", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePeageS", error.response.data.code_message)
                    //console.log(error)
            })
    },

    RecettePeageThomasset({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=Thomasset"
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages-gare" + query)
            .then(response => {
                commit("setRecettePeageT", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePeageT", error.response.data.code_message)
                    //console.log(error)
            })
    },

    RecettePeageMoape({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin + "&gare=Moape"
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "recettes-peages-gare" + query)
            .then(response => {
                commit("setRecettePeageM", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRecettePeageM", error.response.data.code_message)
                    //console.log(error)
            })
    },
    // Fin recette peage par Gare
    RedevancePesage({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin=" + data.annee_fin
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "redevances-pesages" + query)
            .then(response => {
                commit("setRedevancePesage", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRedevancePesage", error.response.data.code_message)
                    //console.log(error)
            })
    },
    RessourcesAffectees({ commit }, data) {
        var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin="+data.annee_fin+ "&periode="+data.periode+ "&nature="+data.nature
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "ressources-affectees" + query)
            .then(response => {
                commit("setRessourcesAffectees", response.data.donnees)
            })
            .catch(error => {
                commit("setMsgFailRessourcesAffectees", error.response.data.code_message)
                    //console.log(error)
            })
    },
    RessourcesAireStationnement({ commit }, data) {
      var query = ""
      if (data != null) {
          query += "?annee_debut=" + data.annee_debut + "&annee_fin="+data.annee_fin+ "&periode="+data.periode+ "&nature="+data.nature
      }
      HTTP.get(process.env.VUE_APP_AUTHURL + "ressources-aire-stationnement" + query)
          .then(response => {
              commit("setRessourcesAireStationnement", response.data.donnees)
          })
          .catch(error => {
              commit("setMsgFailRessourcesAireStationnement", error.response.data.code_message)
                  //console.log(error)
          })
    },
    loadRedevancePesage({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_redevances_pesage&annee_debut=" + data.annee_debut +"&annee_fin=" + data.annee_fin + "&variation=0")
            .then(response => {
                commit("setArRedevance", response.data.donnees)
            })
            .catch(error => {
                commit("setERedevance", error)
                    //console.log(error)
            })
    },
    loadRedevancePesageV({ commit }, data) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recettes?type=ar_redevances_pesage&annee=" + data + "&variation=1")
            .then(response => {
                commit("setArRedevanceV", response.data.donnees)
            })
            .catch(error => {
                commit("setERedevance", error)
                    //console.log(error)
            })
    },
    loadEtatRessources({commit}, data){
      var query = ""
        if (data != null) {
            query += "?annee_debut=" + data.annee_debut + "&annee_fin="+data.annee_fin + "&periode="+data.periode+ "&base="+data.base
        }
        HTTP.get(process.env.VUE_APP_AUTHURL + "etat-ressources" + query)
            .then(response => {
                commit("setEtatRessources", response.data)
            })
            .catch(error => {
                commit("setMsgFailEtatRessources", error.response.data.code_message)
                    //console.log(error)
            })
    },
    importPeagePesage({commit}, data){
      HTTP.post(process.env.VUE_APP_AUTHURL + "importer-peage-pesage-statistique", data)
        .then(() => {
          commit("setMsgSuccessImportPeagePesage", "Le fichier a bien été importé")
        })
        .catch((error) => {
          commit("setMsgFailImportPeagePesage", error.response.data.erreurs)
        })
    },
}

const mutations = {
    setArLevee: (state, arlevee) => (state.arLevee = arlevee),
    setArRessource: (state, arressource) => (state.arRessource = arressource),
    setArRessourcev: (state, arressourcev) => (state.arRessourcev = arressourcev),
    setArRecetteG: (state, arrecetteg) => (state.arRecetteG = arrecetteg),
    setArRecetteA: (state, arrecetteg) => (state.arRecetteA = arrecetteg),
    setArRecetteS: (state, arrecetteg) => (state.arRecetteS = arrecetteg),
    setArRecetteT: (state, arrecetteg) => (state.arRecetteT = arrecetteg),
    setArRecetteM: (state, arrecetteg) => (state.arRecetteM = arrecetteg),
    setArRecette: (state, arrecette) => (state.arRecette = arrecette),
    setArRedevance: (state, arredevance) => (state.arRedevance = arredevance),
    setArRedevanceV: (state, arredevancev) => (state.arRedevanceV = arredevancev),
    setELevee: (state, elevee) => (state.errorLevee = elevee),
    setERessource: (state, eressource) => (state.errorRessource = eressource),
    setERecette: (state, eressource) => (state.errorRessource = eressource),
    setERedevance: (state, errorRedevance) => (state.ERedevance = errorRedevance),
    setRecettePeage: (state, recettepeage) => (state.recettePeage = recettepeage),
    setMsgFailRecettePeage: (state, msgfailrecettepeage) => (state.msgFailRecettePeage = msgfailrecettepeage),
    setRecettePeageG: (state, recettepeageg) => (state.recettePeageG = recettepeageg),
    setRecettePesageG: (state, recettepesageg) => (state.recettePesageG = recettepesageg),
    setRecettePeageA: (state, recettepeageg) => (state.recettePeageA = recettepeageg),
    setRecettePeageS: (state, recettepeageg) => (state.recettePeageS = recettepeageg),
    setRecettePeageT: (state, recettepeageg) => (state.recettePeageT = recettepeageg),
    setRecettePeageM: (state, recettepeageg) => (state.recettePeageM = recettepeageg),
    setMsgFailRecettePeageG: (state, msgfailrecettepeageg) => (state.msgFailRecettePeageG = msgfailrecettepeageg),
    setMsgFailRecettePesageG: (state, msgfailrecettepesageg) => (state.msgFailRecettePesageG = msgfailrecettepesageg),
    setMsgFailRecettePeageA: (state, msgfailrecettepeageg) => (state.msgFailRecettePeageA = msgfailrecettepeageg),
    setMsgFailRecettePeageS: (state, msgfailrecettepeageg) => (state.msgFailRecettePeageS = msgfailrecettepeageg),
    setMsgFailRecettePeageT: (state, msgfailrecettepeageg) => (state.msgFailRecettePeageT = msgfailrecettepeageg),
    setRedevancePesage: (state, recettepeage) => (state.redevancePesage = recettepeage),
    setMsgFailRedevancePesage: (state, msgfailrecettepeage) => (state.msgFailRedevancePesage = msgfailrecettepeage),
    setRessourcesAffectees: (state, recettepeage) => (state.ressourcesAffectees = recettepeage),
    setRessourcesAireStationnement: (state, recettepeage) => (state.ressourcesAireStationnement = recettepeage),
    setMsgFailRessourcesAffectees: (state, msgfailrecettepeage) => (state.msgFailRessourcesAffectees = msgfailrecettepeage),
    setMsgFailRessourcesAireStationnement: (state, msgfailrecettepeage) => (state.msgFailRessourcesAireStationnement = msgfailrecettepeage),
    setEtatRessources: (state, recettepeage) => (state.etatRessources = recettepeage),
    setMsgFailEtatRessources: (state, msgfailrecettepeage) => (state.msgFailEtatRessources = msgfailrecettepeage),
    setMsgSuccessImportPeagePesage: (state, msgfailrecettepeage) => (state.msgSuccessImportPeagePesage = msgfailrecettepeage),
    setMsgFailImportPeagePesage: (state, msgfailrecettepeage) => (state.msgFailImportPeagePesage = msgfailrecettepeage),
}

export default {
    state,
    getters,
    actions,
    mutations
}