import HTTP from "./../../Api/auth"

const state = {
  leveefond: [],
  leveefnd: [],
  successLevee: "",
  errorsLevee: "",
  amortissements:"",
  msgSuccessAmortissement:"",
  msgFailAmortissement:"",
  paiement:"",
  msgFailPaiement:"",
}

const getters = {
  leveefond: state => state.leveefond,
  leveefnd: state => state.leveefnd,
  successLevee: state => state.successLevee,
  errorsLevee: state => state.errorsLevee,
  amortissements: state => state.amortissements,
  msgSuccessAmortissement: state => state.msgSuccessAmortissement,
  msgFailAmortissement: state => state.msgFailAmortissement,
  paiement: state => state.paiement,
  msgFailPaiement: state => state.msgFailPaiement,
}

const actions = {
  leveeFonds({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "levee-fonds" + query)
      .then(response => {
        commit("setLeveeFond", response.data)
        commit("setErrors", "")
      })
      .catch(errors => {
        commit("setLeveeFond", "")
        commit("setErrors", errors)
      })
  },
  getLeveeFond({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "levee-fonds/"+ data)
      .then(response => {
        commit("setLeveeFnd", response.data)
        commit("setErrors", "")
      })
      .catch(errors => {
        commit("setLeveeFnd", "")
        commit("setErrors", errors)
      })
  },
  pageLevees({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setLeveeFond", response.data)
        //console.log(response.data)
      })
      .catch((errors) => {
        commit("setErrors", errors)
      })
  },
  saveLeveeFonds({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "levee-fonds", data)
      .then(response => {
        console.log(response.data)
        commit("setSuccess", "Levée de fonds créé avec succès")
        commit("setErrors", "")
      })
      .catch(error => {
        commit("setSuccess", "")
        commit("setErrors", error)
      })
  },
  updateLeveeFonds({ commit }, data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "levee-fonds/"+data.id, data)
      .then(() => {
        commit("setSuccess", "Dette modifiée avec succès")
        commit("setErrors", "")
      })
      .catch(error => {
        commit("setSuccess", "")
        commit("setErrors", error)
      })
  },
  importDette({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-levee-fonds", data)
    .then(response => {
        console.log(response.data)
        commit("setSuccess", "Le fichier a bien été importé")
        commit("setErrors", "")
    })
    .catch(error => {
        commit("setSuccess", "")
        commit("setErrors", error.response.data.erreurs)
    })
  },
  importerAmortissements({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-amortissement/"+data.id_dette, data)
    .then(response => {
        console.log(response.data)
        commit("setMsgSuccessAmortissement", "Le fichier a bien été importé")
        commit("setMsgFailAmortissement", "")
    })
    .catch(error => {
        commit("setMsgSuccessAmortissement", "")
        commit("setMsgFailAmortissement", error.response.data.erreurs)
    })
  },
  postPaiement({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "paiement-dette",data)
    .then(() =>{
      commit("setPaiement","Le paiement a été enregistré.")
    })
    .catch(error => {
      commit("setMsgFailPaiement",error.response.data.erreurs)
    })
  },
  getAmortissements({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "importer-amortissement/"+data.id_dette)
    .then(response => {
        commit("setAmortissements", response.data)
        commit("setMsgFailAmortissement", "")
    })
    .catch(error => {
        commit("setAmortissements", "")
        commit("setMsgFailAmortissement", error.response.data.erreurs)
    })
  },
}

const mutations = {
  setLeveeFond: (state, leveefond) => (state.leveefond = leveefond),
  setLeveeFnd: (state, leveefnd) => (state.leveefnd = leveefnd),
  setSuccess: (state, successMsg) => (state.successLevee = successMsg),
  setErrors: (state, errorsMsg) => (state.errorsLevee = errorsMsg),
  setAmortissements: (state,amortissement) => (state.amortissements = amortissement),
  setMsgSuccessAmortissement: (state,msgsuccessamortissement) => (state.msgSuccessAmortissement = msgsuccessamortissement),
  setMsgFailAmortissement: (state,msgfailamortissement) => (state.msgFailAmortissement = msgfailamortissement),
  setPaiement: (state,msgsuccesspaiement) => (state.paiement = msgsuccesspaiement),
  setMsgFailPaiement: (state,msgfailamortissement) => (state.msgFailPaiement = msgfailamortissement),
}

export default {
  state,
  getters,
  actions,
  mutations
}
