import HTTP from "../../Api/auth"

const state ={
  listAvenants:[],
  msgSuccessAvenant:"",
  msgFailAvenant:"",
  avenant:""
}

const getters={
  listAvenants: state => state.listAvenants,
  msgSuccessAvenant: state => state.msgSuccessAvenant,
  msgFailAvenant: state => state.msgFailAvenant,
  avenant: state => state.avenant
}

const actions = {
  getListAvenants({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"marches/"+data.id+"/avenants").then(response => {
     commit("setListAvenants",response.data)
    }).catch(error => {
     commit("setMsgFailAvenant",error.response.data.erreurs)
    })
  },
  getAvenant({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/avenants/"+data.id).then(response => {
     commit("setAvenant",response.data)
    }).catch(error => {
     commit("setMsgFailAvenant",error.response.data.erreurs)
    })
  },
  addAvenant({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/avenants",data).then(response => {
     commit("setMsgSuccessAvenant",response.data)
    }).catch(error => {
     commit("setMsgFailAvenant",error.response.data.erreurs)
    })
  },
  updateAvenant({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/avenants/"+data.id, data).then(response => {
     commit("setMsgSuccessAvenant",response.data)
    }).catch(error => {
     commit("setMsgFailAvenant",error.response.data.erreurs)
    })
  },
  deleteAvenant({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/avenants/"+data.id, data).then(response => {
     commit("setMsgSuccessAvenant",response.data)
    }).catch(error => {
     commit("setMsgFailAvenant",error.response.data.erreurs)
    })
  },
}

const mutations = {
  setListAvenants: (state, listavenant) => (state.listAvenants = listavenant),
  setMsgSuccessAvenant: (state, msgsuccessavenant) => (state.msgSuccessAvenant = msgsuccessavenant),
  setMsgFailAvenant: (state, msgfailavenant) => (state.msgFailAvenant = msgfailavenant),
  setAvenant: (state, avenants) => (state.avenant = avenants),
}

export default {
  state,
  getters,
  actions,
  mutations
}