import HTTP from './../../Api/auth'
const state = {
  files:[],
  singleFile:'',
  filerrors:'',
  file:[]
}

const getters = {
  singleFile: state => state.singleFile,
  filerrors: state => state.filerrors,
  file: state => state.file,
  files: state => state.files,
}

const actions = {
  async saveFile({commit},data){
    let formData = new FormData()
    formData.append('fichier',data)
    await HTTP.post(process.env.VUE_APP_AUTHURL+"uploads", formData,{
      headers:{
          'Content-Type': 'multipart/form-data'
      }}).then((response) => {
      commit("setFiles", response.data)
    }).catch((error) => {
      if (error.response) {
        commit("setFileErors", error.response.data.code_message)        
      } else {
        commit("setFileErors", "Une erreur s'est produite. Veuillez recommencer.")        
      }
    })
  },
}

const mutations = {
  setFiles: (state, files) => (
    state.files = files
  ),
  singleFile: (state, singlefile) => (
    state.singleFile = singlefile
  ),
  setFileErors: (state, error) => (
    state.filerrors = error
  ),
  singlefile: (state, error) => (
    state.file = error
  )
}

export default {
    state,
    getters,
    actions,
    mutations
}
