<template>
  <header id="header">
    <nav class="navbar">
      <a class="navbar-brand">
        <img src="../assets/logo.png"
             style="width:80px"/>
      </a>
      <div class="user_info">
        <div class="user_tab">
          <div class="img_u">
            <img src="../assets/img/user-icon.png" />
            <button class="btn"
                    v-on:click="userLogout">Déconnexion</button>
          </div>
          <div class="info_u">
            <label class="u_name">{{ user.nom }}</label>
            <label>{{ user.role }}</label>
          </div>
        </div>
      </div>
      <!-- <button id="menu_switch"><span></span><span></span><span></span></button> -->
    </nav>
  </header>
</template>
<style>
@import "./theheader.css";
</style>
<script>
export default {
  name: "RwvHeader",
  data: () => ({
    user: {
      nom: "",
      role: "",
      photo: ""
    }
  }),
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user.nom = userdetails.nom
    this.user.role = userdetails.user_role
  },
  methods: {
    userLogout() {
      localStorage.removeItem("user")
      localStorage.removeItem("userauth")
      localStorage.removeItem("token")
      this.$router.push("/")
    }
  }
}
</script>
