import axios from "axios"
import Api from "./../../Api"
import HTTP from "./../../Api/auth"
const state = {
    users: [],
    userMsg: "",
    listUsers: [],
    tokenVal: localStorage.getItem("user"),
    errors: "",
    forgotmsg: "",
    resetmsg: ""
}

const formData = new FormData()
formData.append("grant_type", "client_credentials")
formData.append("client_id", 2)
formData.append("client_secret", process.env.VUE_APP_TOKEN)

const getters = {
    usersDetails: state => state.users,
    userMsg: state => state.userMsg,
    listUsers: state => state.listUsers,
    tokenVal: state => state.tokenVal,
    errors: state => state.errors,
    forgotmsg: state => state.forgotmsg,
    resetmsg: state => state.resetmsg
}

const actions = {
    loginUser({ commit }, data) {
        // if (localStorage.getItem("token") === null) {
        Api.post(process.env.VUE_APP_APIURL + "oauth/token", formData)
            .then(response => {
                localStorage.setItem("token", response.data.access_token)
                commit("setErors", "")
                Api.post(process.env.VUE_APP_APIURL + "api/v1/utilisateurs/authentifier", data)
                    .then(response => {
                        commit("setUsers", response.data)
                        const userdetails = {
                            user_id: response.data.oauth.utilisateur.id,
                            user_role: response.data.oauth.utilisateur.role,
                            user_profil:response.data.oauth.utilisateur.profil_metier,
                            etat:response.data.oauth.utilisateur.etat,
                            nom: response.data.oauth.utilisateur.nom
                        }
                        localStorage.setItem("user", JSON.stringify(userdetails))
                        const authkey = {
                            value: response.data.oauth.access_token,
                            expiry: response.data.oauth.expires_at
                        }
                        localStorage.setItem("userauth", JSON.stringify(authkey))
                    })
                    .catch(errors => {
                        commit("setErors", errors.response.data.code_message)
                        localStorage.setItem("user", "")
                        localStorage.setItem("userauth", "")
                    })
            })
            .catch(errors => {
                commit("setErors", errors.response.data.code_message)
            })
        // } else {
        //     Api.post(process.env.VUE_APP_APIURL + "api/v1/utilisateurs/authentifier", data)
        //         .then(response => {
        //             commit("setUsers", response.data)
        //             const userdetails = {
        //                 user_id: response.data.oauth.utilisateur.id,
        //                 user_role: response.data.oauth.utilisateur.role,
        //                 user_profil:response.data.oauth.utilisateur.profil_metier,
        //                 etat:response.data.oauth.utilisateur.etat,
        //                 nom: response.data.oauth.utilisateur.nom
        //             }
        //             localStorage.setItem("user", JSON.stringify(userdetails))
        //             const authkey = {
        //                 value: response.data.oauth.access_token,
        //                 expiry: response.data.oauth.expires_at
        //             }
        //             localStorage.setItem("userauth", JSON.stringify(authkey))
        //         })
        //         .catch(errors => {
        //             commit("setErors", errors.response.data.code_message)
        //             localStorage.setItem("user", "")
        //             localStorage.setItem("userauth", "")
        //         })
        // }
    },
    forgotPassword({ commit }, data) {
      Api.post(process.env.VUE_APP_APIURL + "oauth/token", formData).then(token => {
        localStorage.setItem("token", token.data.access_token)
        commit("setErors", "")
        Api.post(process.env.VUE_APP_AUTHURL + "utilisateurs/reinitialiser-mot-de-passe", data)
            .then(response => {
                console.log(response.data.code_message)
                commit("forgotMsg", "Un mail va vous être envoyé.")
                commit("setErors", "")
            })
            .catch(errors => {
                commit("setErors", errors.response.data.code_message)
                commit("forgotMsg", "")
            })
      })
    },
    async verfiyCode({ commit }, otp) {
        const response = await axios.post("http://localhost/json/fetchusers.php", otp)
        commit("addNewUser", response.data)
    },
    passwordReset({ commit }, { id, password }) {
      Api.post(process.env.VUE_APP_APIURL + "oauth/token", formData).then(token => {
        localStorage.setItem("token", token.data.access_token)
        commit("setErors", "")
        Api.put(process.env.VUE_APP_AUTHURL + "utilisateurs/" + id + "/mot-de-passe", password)
            .then(response => {
                console.log(response.data.code_message)
                commit("resetMsg", "Mot de passe a bien été réinitialisé.")
                commit("setErors", "")
            })
            .catch(errors => {
                commit("setErors", errors.response.data.code_message)
                commit("resetMsg", "")
            })
      })
    },
    async deleteUser({ commit }, id) {
        await axios.delete(`http://localhost/json/fetchusers.php/${id}`)
        commit("removeUser", id)
    },
    async getToken({ commit }) {
        Api.post(process.env.VUE_APP_APIURL + "oauth/token", formData)
            .then(response => {
                localStorage.setItem("token", response.data.access_token)
                commit("setErors", "")
            })
            .catch(errors => {
                commit("setErors", errors.response.data.code_message)
            })
    },
    addUser({ commit }, data) {
        HTTP.post(process.env.VUE_APP_AUTHURL + "utilisateurs", data)
            .then(() => {
                commit("setUserMsg", "Nouveau compte utilisateur créé.")
                commit("setErors", "")
            })
            .catch(errors => {
                console.log(errors.response.data.code_message)
                commit("setErors", errors.response.data.code_message)
                commit("setUserMsg", "")
            })
    },
    getListUsers({ commit },data) {
      let query=""
      var count=1
      var used=0
      console.log(data)
      if (data!=null) {
        query="?filtres=["  
        data.forEach(filtre => {
          if (filtre.type == "date") {
            if (filtre.operation!="" && filtre.valeur!="") {
              if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }else{
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }
            }
          }else{
            if (filtre.valeur!="" && filtre.nature!="") {
              if (count>1 && used !=0) {
                query+=","
                console.log(count)
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
              used+=1
            }
          }
          count+=1
        })
        query+="]"
      }      
        HTTP.get(process.env.VUE_APP_AUTHURL + "utilisateurs"+query)
            .then(response => {
                commit("setListUsers", response.data)
            })
            .catch(errors => {
                commit("setErors", errors)
            })
    },
    pageUsers({ commit },data) {      
        HTTP.get(process.env.VUE_APP_AUTHURL + data)
            .then(response => {
                commit("setListUsers", response.data)
            })
            .catch(errors => {
                commit("setErors", errors)
            })
    },
    getUser({ commit }, id) {
        HTTP.get(process.env.VUE_APP_AUTHURL + "utilisateurs/" + id)
            .then(response => {
                commit("setUsers", response.data.utilisateur)
            })
            .catch(errors => {
                commit("setErors", errors)
            })
    },
    updateUser({ commit }, { id, data }) {
        HTTP.put(process.env.VUE_APP_AUTHURL + "utilisateurs/" + id, data)
            .then(() => {
                commit("setUserMsg", "Modification effectuée avec succès.")
            })
            .catch(errors => {
                commit("setErors", errors)
            })
    }
}

const mutations = {
    setUsers: (state, users) => (state.users = users),
    setUserMsg: (state, usermsg) => (state.userMsg = usermsg),
    setListUsers: (state, listusers) => (state.listUsers = listusers),
    setErors: (state, data) => (state.errors = data),
    setToken: (state, response) => (state.tokenVal = response.tokenVal),
    forgotMsg: (state, response) => (state.forgotmsg = response),
    resetMsg: (state, response) => (state.resetmsg = response),
    removeUser: (state, id) => (state.users.filter(user => user.id !== id), state.users.splice(user => user.id, 1))
}

export default {
    state,
    getters,
    actions,
    mutations
}