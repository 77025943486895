import HTTP from "../../Api/auth"

const state = {
  business:"",
  failBusiness:"",
  addProjet:"",
  failAddProjet:"",
  listProjets:"",
  failListProjets:"",
  projet:"",
  failProjet:"",
  msgSuccessProjet : "",
  msgFailProjet :"",
  addProduit:"",
  failAddProduit:"",
  listProduits:"",
  failListProduits:"",
  produit:"",
  failProduit:"",
  msgSuccessProduit : "",
  msgFailProduit :"",
  addCharge:"",
  failAddCharge:"",
  listCharges:"",
  failListCharges:"",
  charge:"",
  failCharge:"",
  msgSuccessCharge : "",
  msgFailCharge :"",
  addRessource : "",
  failAddRessource :"",
  listRessources : "",
  failListRessources : "",
  ressource : "",
  failRessource : "",
  msgSuccessRessouce : "",
  msgFailRessouce :"",
}

const getters = {
  business : state => state.business,
  failBusiness : state => state.failBusiness,
  addProjet : state => state.addProjet,
  failAddProjet : state => state.failAddProjet,
  listProjets : state => state.listProjets,
  failListProjets : state => state.failListProjets,
  projet : state => state.projet,
  failProjet : state => state.failProjet,
  msgSuccessProjet : state => state.msgSuccessProjet,
  msgFailProjet : state => state.msgFailProjet,
  addProduit : state => state.addProduit,
  failAddProduit :state => state.failAddProduit,
  listProduits : state => state.listProduits,
  failListProduits : state => state.failListProduits,
  produit : state => state.produit,
  failProduit : state => state.failProduit,
  msgSuccessProduit : state => state.msgSuccessProduit,
  msgFailProduit : state => state.msgFailProduit,
  addCharge : state => state.addCharge,
  failAddCharge :state => state.failAddCharge,
  listCharges : state => state.listCharges,
  failListCharges : state => state.failListCharges,
  charge : state => state.charge,
  failCharge : state => state.failCharge,
  msgSuccessCharge : state => state.msgSuccessCharge,
  msgFailCharge : state => state.msgFailCharge,
  addRessource : state => state.addRessource,
  failAddRessource :state => state.failAddRessource,
  listRessources : state => state.listRessources,
  failListRessources : state => state.failListRessources,
  ressource : state => state.ressource,
  failRessource : state => state.failRessource,
  msgSuccessRessource : state => state.msgSuccessRessource,
  msgFailRessource : state => state.msgFailRessource,
  
}

const actions = {
  postProjet({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans",data)
      .then(response => {
        commit("setAddProjet", response.data)
      })
      .catch(error => {
        commit("setFailAddProjet", error.response.data.code_message)
        //console.log(error)
      })
  },
  recapBusiness({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.id+"/recap?date_debut="+data.date_debut+"&date_fin="+data.date_fin)
      .then(response => {
        commit("setBusiness", response.data.business_plan[0])
      })
      .catch(error => {
        commit("setFailBusiness", error.response.data.code_message)
        //console.log(error)
      })
  },
  getListProjets({ commit }){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans")
      .then(response => {
        commit("setListProjets", response.data)
      })
      .catch(error => {
        commit("setFailListProjets", error)
        //console.log(error)
      })
  },
  getProjet({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data)
      .then(response => {
        commit("setProjet", response.data.donnees[0])
      })
      .catch(error => {
        commit("setFailProjet", error)
        //console.log(error)
      })
  },
  putProjet({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "business-plans/"+data.id, data)
      .then(response => {
        commit("setMsgSuccessProjet", response.data)
      })
      .catch(error => {
        commit("setMsgFailProjet", error.response.data.code_message)
        //console.log(error)
      })
  },
  postProduit({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits",data)
      .then(response => {
        commit("setAddProduit", response.data)
      })
      .catch(error => {
        commit("setFailAddProduit", error.response.data.code_message)
        //console.log(error)
      })
  },
  getListProduits({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data+"/produits")
      .then(response => {
        commit("setListProduits", response.data)
      })
      .catch(error => {
        commit("setFailListProduits", error)
        //console.log(error)
      })
  },
  getProduit({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.id)
      .then(response => {
        commit("setProduit", response.data.produit[0])
      })
      .catch(error => {
        commit("setFailProduit", error)
        //console.log(error)
      })
  },
  putProduit({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.id, data)
      .then(response => {
        commit("setMsgSuccessProduit", response.data)
      })
      .catch(error => {
        commit("setMsgFailProduit", error.response.data.code_message)
        //console.log(error)
      })
  },
  postCharge({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/charges",data)
      .then(response => {
        commit("setAddCharge", response.data)
      })
      .catch(error => {
        commit("setFailAddCharge", error.response.data.code_message)
        //console.log(error)
      })
  },
  getListCharges({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/charges")
      .then(response => {
        commit("setListCharges", response.data)
      })
      .catch(error => {
        commit("setFailListCharges", error)
        //console.log(error)
      })
  },
  getCharge({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/charges/"+data.charge)
      .then(response => {
        commit("setCharge", response.data.charge[0])
      })
      .catch(error => {
        commit("setFailCharge", error)
        //console.log(error)
      })
  },
  putCharge({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/charges/"+data.id, data)
      .then(response => {
        commit("setMsgSuccessCharge", response.data)
      })
      .catch(error => {
        commit("setMsgFailCharge", error.response.data.code_message)
        //console.log(error)
      })
  },
  postRessource({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/ressources",data)
      .then(response => {
        commit("setAddRessource", response.data)
      })
      .catch(error => {
        commit("setFailAddRessource", error.response.data.code_message)
        //console.log(error)
      })
  },
  getListRessources({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/ressources")
      .then(response => {
        commit("setListRessources", response.data)
      })
      .catch(error => {
        commit("setFailListRessources", error)
        //console.log(error)
      })
  },
  getRessource({ commit },data){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/ressources/"+data.ressource)
      .then(response => {
        
        commit("setRessource", response.data.ressource[0])
      })
      .catch(error => {
        commit("setFailRessource", error)
        //console.log(error)
      })
  },
  putRessource({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "business-plans/"+data.projet+"/produits/"+data.produit+"/ressources/"+data.id, data)
      .then(response => {
        commit("setMsgSuccessRessource", response.data)
      })
      .catch(error => {
        commit("setMsgFailRessource", error.response.data.code_message)
        //console.log(error)
      })
  },
}

const mutations = {
  setBusiness : (state , business) =>(state.business = business),
  setFailBusiness : (state , business) =>(state.failBusiness = business),
  setAddProjet : (state , projet) =>(state.addProjet = projet),
  setFailAddProjet : (state , projet) =>(state.failAddProjet = projet),
  setListProjets : (state , projet) =>(state.listProjets = projet),
  setFailListProjets : (state , projet) =>(state.failListProjets = projet),
  setProjet : (state , projet) =>(state.projet = projet),
  setFailProjet : (state , failProjet) =>(state.failProjet = failProjet),
  setMsgSuccessProjet : (state , projet) =>(state.msgSuccessProjet = projet),
  setMsgFailProjet : (state , failProjet) =>(state.msgfailProjet = failProjet),

  setAddProduit : (state , produit) =>(state.addProduit = produit),
  setFailAddProduit : (state , produit) =>(state.failAddProduit = produit),
  setListProduits : (state , produit) =>(state.listProduits = produit),
  setFailListProduits : (state , produit) =>(state.failListProduits= produit),
  setProduit : (state , produit) =>(state.produit = produit),
  setFailProduit : (state , produit) =>(state.failProduit = produit),
  setMsgSuccessProduit : (state , produit) =>(state.msgSuccessProduit = produit),
  setMsgFailProduit : (state , produit) =>(state.msgFailProduit = produit),

  setAddCharge : (state , charge) =>(state.addCharge = charge),
  setFailAddCharge :(state , charge) =>(state.failAddCharge = charge),
  setListCharges : (state , charge) =>(state.listCharges = charge),
  setFailListCharges : (state , charge) =>(state.failListCharges = charge),
  setCharge : (state , charge) =>(state.charge = charge),
  setFailCharge : (state , charge) =>(state.failCharge = charge),
  setMsgSuccessCharge : (state , charge) =>(state.msgSuccessCharge = charge),
  setMsgFailCharge : (state , charge) =>(state.msgFailCharge = charge),

  setAddRessource : (state , ressource) =>(state.addRessource = ressource),
  setFailAddRessource :(state , ressource) =>(state.failAddRessource = ressource),
  setListRessources : (state , ressource) =>(state.listRessources = ressource),
  setFailListRessources : (state , ressource) =>(state.failListRessources = ressource),
  setRessource : (state , ressource) =>(state.ressource = ressource),
  setFailRessource : (state , ressource) =>(state.failRessource = ressource),
  setMsgSuccessRessource : (state , ressource) =>(state.msgSuccessRessource = ressource),
  setMsgFailRessource : (state , ressource) =>(state.msgFailRessource = ressource),
}

export default {
  state,
  getters,
  actions,
  mutations
}
