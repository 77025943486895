import HTTP from "./../../Api/auth"

const state = {
  notifDecompte: [],
  failNotifDecompte: [],
  successDelNotifDecompte: "",
  failDelNotifDecompte: "",
  
}

const getters = {
  notifDecompte: state => state.notifDecompte,
  failNotifDecompte: state => state.failNotifDecompte,
  successDelNotifDecompte: state => state.successDelNotifDecompte,
  failDelNotifDecompte: state => state.failDelNotifDecompte,
}

const actions = {
  getNotifDecompte({ commit }) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "notifications")
      .then(respnse => {
        commit("setNotifDecompte", respnse.data)
        commit("setFailNotifDecompte", "")
      })
      .catch(errors => {
        commit("setNotifDecompte", "")
        commit("setFailNotifDecompte", errors)
      })
  },
  supprimerNotificationDecompte({ commit }, data) {
    HTTP.delete(process.env.VUE_APP_AUTHURL + "notifications/" + data)
      .then(response => {
        commit("setSuccessDelNotifDecompte", response.data)
        commit("setFailDelNotifDecompte", "")
      })
      .catch(errors => {
        commit("setFailDelNotifDecompte", errors)
        commit("setSuccessDelNotifDecompte", "")
      })
  },
  
}

const mutations = {
  setNotifDecompte: (state, donneesRecus) => (state.notifDecompte = donneesRecus),
  setFailNotifDecompte: (state, donneesRecus) => (state.failNotifDecompte = donneesRecus),
  setSuccessDelNotifDecompte: (state, reponseSucces) => (state.successDelNotifDecompte = reponseSucces),
  setFailDelNotifDecompte: (state, reponseError) => (state.failDelNotifDecompte = reponseError),
}

export default {
  state,
  getters,
  actions,
  mutations
}
