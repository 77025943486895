import HTTP from "./../../Api/auth"

const state = {
  virements: [],
  singleVirement: "",
  singleOrdres: "",
  virementsErrors: "",
  virement: []
}

const getters = {
  virements: state => state.virements,
  singleVirement: state => state.singleVirement,
  singleOrdres: state => state.singleOrdres,
  virementsErrors: state => state.virementsErrors,
  virement: state => state.virement
}

const actions = {
  Virements({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "ordre-virements"+query)
      .then(response => {
        commit("setVirements", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  pageVirements({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setVirements", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  saveOrdre({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "ordre-virements", data)
      .then(response => {
        console.log(response.data)
        commit("setOrdres", "Ordre de virement a été créé.")
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  async getVirement({ commit }, { id_ordre_virement }) {
    await HTTP.get(process.env.VUE_APP_AUTHURL + "ordre-virements/" + id_ordre_virement)
      .then(response => {
        console.log(response.data.ordre_virement)
        commit("setVirement", response.data.ordre_virement)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  }
}

const mutations = {
  setVirements: (state, virements) => (state.virements = virements),
  setVirement: (state, virement) => (state.virement = virement),
  setErrors: (state, error) => (state.virementsErrors = error),
  singleVirement: (state, singeVire) => (state.singleVirement = singeVire),
  setOrdres: (state, singeordre) => (state.singleOrdres = singeordre)
}

export default {
  state,
  getters,
  actions,
  mutations
}
