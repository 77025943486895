import HTTP from "./../../Api/auth"

const state = {
  comptes: [],
  comptesTransactions:[],
  successCompte: "",
  errorsCompte: "",
  // CRUD COMPTE BANCAIRE
  compteBancaire:"",
  msgSuccessCompteBancaire:"",
  msgFailCompteBancaire:"",
}

const getters = {
  comptes: state => state.comptes,
  comptesTransactions: state => state.comptesTransactions,
  successCompte: state => state.successCompte,
  errorsCompte: state => state.errorsCompte,

  compteBancaire:state =>state.compteBancaire,
  msgSuccessCompteBancaire:state =>state.msgSuccessCompteBancaire,
  msgFailCompteBancaire:state =>state.msgFailCompteBancaire,
}

const actions = {
  loadBankAccounts({ commit }) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "comptes-bancaires/recapitulatif")
      .then(response => {
        commit("setCompteBancaires", response.data)
      })
      .catch((error) => {
        commit("setErrors",error)
        //console.log(error)
      })
  },
  loadBanktransactions({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "comptes-bancaires/"+ data +"/transactions")
      .then(response => {
        commit("setCompteTransactions", response.data)
      })
      .catch((error) => {
        commit("setErrors",error)
        //console.log(error)
      })
  },
  pageComptes({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setCompteBancaires", response.data)
      })
      .catch(errors => {
        commit("setErrors", errors)
      })
  },
  pageTransactions({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+data)
        .then(response =>{
          commit("setCompteTransactions",response.data)
        })
        .catch((errors) =>{
          commit("setErors",errors)
        })
  },
  pageTransactionsCat({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+data)
        .then(response =>{
          commit("setCompteTransactions",response.data)

        }).catch((errors) =>{
          commit("setErrors",errors)
    })
  },
  // ---------------------------------------------------------------------------------------------------------------
  // ------------------------------------------- CRUD COMPTE BANCAIRE ----------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------
  getCompteBancaire({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "comptes-bancaires/"+ data)
      .then(response => {
        commit("setCompteBancaire", response.data.compte_bancaire)
      })
      .catch((error) => {
        commit("setMsgFailCompteBancaire",error.response.data.code_message)
        //console.log(error)
      })
  },
  putCompteBancaire({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "comptes-bancaires/"+data.id, data)
      .then(() => {
        commit("setMsgSuccessCompteBancaire", "Le compte bancaire a été modifié avec succès")
      })
      .catch((error) => {
        commit("setMsgFailCompteBancaire",error.response.data.code_message)
        //console.log(error)
      })
  },
  postCompteBancaire({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "comptes-bancaires", data)
      .then(() => {
        commit("setMsgSuccessCompteBancaire", "Le compte bancaire a été créé avec succès")
      })
      .catch((error) => {
        commit("setMsgFailCompteBancaire",error.response.data.code_message)
        //console.log(error)
      })
  },
}

const mutations = {
  setCompteBancaires: (state, comptebancaire) => (state.comptes = comptebancaire),
  setCompteTransactions:(state, comptetransactions) => (state.comptesTransactions = comptetransactions),
  setSucces: (state, successMsg) => (state.successCompte = successMsg),
  setErrors: (state, errorsMsg) => (state.errorsCompte = errorsMsg),
  // CRUD COMPTE BANCAIRE
  setCompteBancaire:(state,comptebancaire ) => (state.compteBancaire = comptebancaire),
  setMsgSuccessCompteBancaire:(state, msgsuccesscomptebancaire) => (state.msgSuccessCompteBancaire = msgsuccesscomptebancaire),
  setMsgFailCompteBancaire:(state, msgfailcomptebancaire) => (state.msgFailCompteBancaire = msgfailcomptebancaire),
}

export default {
  state,
  getters,
  actions,
  mutations
}
