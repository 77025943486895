import HTTP from "../../Api/auth"


const state = {
    tresorerie:[],
    msgSuccessTresorerie:[],
    msgFailTresorerie:"",
    tresorerieSiege:[],
    msgFailTresorerieSiege:"",
    tresorerieConcession:[],
    msgFailTresorerieConcession:"",
    mouvement: "",
    msgFailMouvement: "",
    previsionsRevenu: "",
    msgFailPrevisionsRevenu: "",
    previsionsCharge: "",
    msgFailPrevisionsCharge: "",
    paiementProche: "",
    msgFailPaiementProche: "",
    detteFournisseur: "",
    msgFailDetteFournisseur: "",
    importTresorerie: "",
    msgFailImportTresorerie: "",
}
const getters ={
    tresorerie: state => state.tresorerie,
    msgSuccessTresorerie: state => state.msgSuccessTresorerie,
    msgFailTresorerie: state => state.msgFailTresorerie,
    tresorerieSiege: state => state.tresorerieSiege,
    msgFailTresorerieSiege: state => state.msgFailTresorerieSiege,
    tresorerieConcession: state => state.tresorerieConcession,
    msgFailTresorerieConcession: state => state.msgFailTresorerieConcession,
    mouvement: state => state.mouvement,
    msgFailMouvement: state => state.msgFailMouvement,
    previsionsRevenu: state => state.previsionsRevenu,
    msgFailPrevisionsRevenu: state => state.msgFailPrevisionsRevenu,
    previsionsCharge: state => state.previsionsCharge,
    msgFailPrevisionsCharge: state => state.msgFailPrevisionsCharge,
    paiementProche: state => state.paiementProche,
    msgFailPaiementProche: state => state.msgFailPaiementProche,
    detteFournisseur: state => state.detteFournisseur,
    msgFailDetteFournisseur: state => state.msgFailDetteFournisseur,
    importTresorerie: state => state.importTresorerie,
    msgFailImportTresorerie: state => state.msgFailImportTresorerie,
}
const actions ={
  
    loadTresorerie({commit},data){
      var query=""
      if (data!=null) {
        query="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"tresorerie"+query)
          .then(response =>{
              commit("setTresorerie",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailTresorerie",error)
          })
    },
    postTresorerie({commit},data){
      HTTP.post(process.env.VUE_APP_AUTHURL+"tresorerie",data)
          .then(response =>{
              commit("setMsgSuccessTresorerie",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailTresorerie",error)
          })
    },
    loadTresorerieSiege_old({commit},data){
      var query=""
      if (data!=null) {
        query="?date="+data+"&base=SIEGE"
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"situation-tresorerie"+query)
          .then(response =>{
              commit("setTresorerieSiege",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailTresorerieSiege",error)
          })
    },
    loadTresorerieSiege({commit},data){
      var query=""
      if (data!=null) {
        query="?date="+data.date+"&base="+data.base
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"situation-tresorerie"+query)
          .then(response =>{
              commit("setTresorerieSiege",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailTresorerieSiege",error)
          })
    },
    loadTresorerieConcession({commit},data){
      var query=""
      if (data!=null) {
        query="?date="+data+"&base=PEAGE"
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"situation-tresorerie"+query)
          .then(response =>{
              commit("setTresorerieConcession",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailTresorerieConcession",error)
          })
    },

    sendMouvement({commit},data){
      HTTP.put(process.env.VUE_APP_AUTHURL+"mouvement-tresorerie/"+data.id, data)
          .then(response =>{
              commit("setMouvement",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailMouvement",error)
          })
    },
    loadPrevisionsRevenu({commit},data){
      var query=""
      if (data!=null) {
        query="?type=revenu&nb_periode="+data.nb_periode+"&date_debut="+data.date_debut+"&date_fin="+data.date_fin
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"projections-financieres"+query)
          .then(response =>{
              commit("setPrevisionsRevenu",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailPrevisionsRevenu",error)
          })
    },
    loadPrevisionsCharge({commit},data){
      var query=""
      if (data!=null) {
        query="?type=charge&nb_periode_prevision="+data.nb_periode_prevision+"&date_debut="+data.date_debut+"&date_fin="+data.date_fin
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"projections-financieres"+query)
          .then(response =>{
              commit("setPrevisionsCharge",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailPrevisionsCharge",error)
          })
    },
    loadPaiementProche({commit},data){
      var query=""
      if (data!=null) {
        query="?date_debut="+data.date_debut+"&nb_jour="+data.nb_jour+"&base="+data.base
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"paiements-proches"+query)
          .then(response =>{
              commit("setPaiementProche",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailPaiementProche",error)
          })
    },
    loadDetteFournisseur({commit},data){
      var query=""
      if (data!=null) {
        query="?date_debut="+data.date_debut+"&date_fin="+data.date_fin+"&groupe="+data.groupe+"&base="+data.base
      }
      HTTP.get(process.env.VUE_APP_AUTHURL+"dette-fournisseur"+query)
          .then(response =>{
              commit("setDetteFournisseur",response.data)
          })
          .catch((error)=>{
              commit("setMsgFailDetteFournisseur",error)
          })
    },
    importerTresorerie({commit},data){
      HTTP.post(process.env.VUE_APP_AUTHURL+"importer-tresorerie",data).then(()=>{
        commit("setImportTresorerie", 'Le fichier a bien été importé')
      }).catch((error)=>{
        commit("setMsgFailImportTresorerie", error.response.data.erreurs)
      })
    }
}

const mutations={
    setTresorerie:(state,reponseRecu) =>(state.tresorerie=reponseRecu),
    setMsgSuccessTresorerie:(state,reponseRecu) =>(state.msgSuccessTresorerie=reponseRecu),
    setMsgFailTresorerie:(state,reponseRecu) =>(state.msgFailTresorerie=reponseRecu),
    setTresorerieSiege:(state,reponseRecu) =>(state.tresorerieSiege=reponseRecu),
    setMsgFailTresorerieSiege:(state,reponseRecu) =>(state.msgFailTresorerieSiege=reponseRecu),
    setTresorerieConcession:(state,reponseRecu) =>(state.tresorerieConcession=reponseRecu),
    setMsgFailTresorerieConcession:(state,reponseRecu) =>(state.msgFailTresorerieConcession=reponseRecu),
    setMouvement:(state,reponseRecu) =>(state.mouvement=reponseRecu),
    setMsgFailMouvement:(state,reponseRecu) =>(state.msgFailMouvement=reponseRecu),
    setPrevisionsRevenu:(state,reponseRecu) =>(state.previsionsRevenu=reponseRecu),
    setMsgFailPrevisionsRevenu:(state,reponseRecu) =>(state.msgFailPrevisionsRevenu=reponseRecu),
    setPrevisionsCharge:(state,reponseRecu) =>(state.previsionsCharge=reponseRecu),
    setMsgFailPrevisionsCharge:(state,reponseRecu) =>(state.msgFailPrevisionsCharge=reponseRecu),
    setPaiementProche:(state,reponseRecu) =>(state.paiementProche=reponseRecu),
    setMsgFailPaiementProche:(state,reponseRecu) =>(state.msgFailPaiementProche=reponseRecu),
    setDetteFournisseur:(state,reponseRecu) =>(state.detteFournisseur=reponseRecu),
    setMsgFailDetteFournisseur:(state,reponseRecu) =>(state.msgFailDetteFournisseur=reponseRecu),
    setImportTresorerie:(state,reponseRecu) =>(state.importTresorerie=reponseRecu),
    setMsgFailImportTresorerie:(state,reponseRecu) =>(state.msgFailImportTresorerie=reponseRecu),
}

export default {
    state,
    getters,
    actions,
    mutations
}