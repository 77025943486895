import HTTP from './../../Api/auth'
const state = {
  prestataires: [],
  prestataire: [],
  msgSuccessPrestataire: "",
  msgErrorPrestataire: "",
  pregerrors:''
}

const getters = {
  prestataires: state => state.prestataires,
  prestataire: state => state.prestataire,
  msgSuccessPrestataire: state => state.msgSuccessPrestataire,
  msgErrorPrestataire: state => state.msgErrorPrestataire,
  pregerrors:state => state.pregerrors
}
const actions = {
  getPrestataires({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"prestataires").then((response) => {
      commit("setPrestataires", response.data)
    }).catch((error) => {
      commit("setErors", error.response.data.code_message)
    })
  },
  getPrestataire({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"prestataires/"+data.id).then((response) => {
      commit("setPrestataire", response.data)
    }).catch((error) => {
      commit("setPregerrors", error.response.data.code_message)
    })
  },
  postPrestataire({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"prestataires",data).then((response) => {
      commit("setMsgSuccessPrestataire", response.data)
    }).catch((error) => {
      commit("setMsgErrorPrestataire", error.response.data.code_message)
    })
  },
  putPrestataire({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"prestataires/"+data.id,data).then((response) => {
      commit("setMsgSuccessPrestataire", response.data)
    }).catch((error) => {
      commit("setMsgErrorPrestataire", error.response.data.code_message)
    })
  },
  deletePrestataire({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"prestataires/"+data.id, data).then((response) => {
      commit("setMsgSuccessPrestataire", response.data)
    }).catch((error) => {
      commit("setMsgErrorPrestataire", error.response.data.erreurs)
    })
  },

}
const mutations = {
  setPrestataires: (state, prestataires) => (
    state.prestataires = prestataires
  ),
  setPrestataire: (state, prestataire) => (
    state.prestataire = prestataire
  ),
  setMsgSuccessPrestataire: (state, msgsuccessprestataires) => (
    state.msgSuccessPrestataire = msgsuccessprestataires
  ),
  setMsgErrorPrestataire: (state, msgerrorprestataires) => (
    state.msgErrorPrestataire = msgerrorprestataires
  ),
  setPregerrors: (state, pregerrors) => (
    state.pregerrors = pregerrors
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
