import HTTP from './../../Api/auth'
const state = {
  situations_n1:"",
  msgFailSituations_n1:"",
  situation_n1:"",
  situation_id_n1:"",
  situations_n2:"",
  msgFailSituations_n2:"",
  situation_n2:"",
  situation_id_n2:"",
  situations_n3:"",
  msgFailSituations_n3:"",
  situation_n3:"",
  situation_id_n3:"",
  situations_n4:"",
  msgFailSituations_n4:"",
  situation_n4:"",
  situation_id_n4:"",
}

const getters = {
  situations_n1 : state => state.situations_n1,
  msgFailSituations_n1 : state => state.msgFailSituation_n1,
  situation_n1 : state => state.situation_n1,
  situation_id_n1 : state => state.situation_id_n1,
  situations_n2 : state => state.situations_n2,
  msgFailSituations_n2 : state => state.msgFailSituation_n2,
  situation_n2 : state => state.situation_n2,
  situation_id_n2 : state => state.situation_id_n2,
  situations_n3 : state => state.situations_n3,
  msgFailSituations_n3 : state => state.msgFailSituation_n3,
  situation_n3 : state => state.situation_n3,
  situation_id_n3 : state => state.situation_id_n3,
  situations_n4 : state => state.situations_n4,
  msgFailSituations_n4 : state => state.msgFailSituation_n4,
  situation_n4 : state => state.situation_n4,
  situation_id_n4 : state => state.situation_id_n4,
}

const actions = {
  // Situation niveau 1
  postSituation_n1({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"situation-niveau-1",data).then(() =>{
      commit("setSituation_n1","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n1",error)
    })
  },
  getSituation_n1({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-1").then(response =>{
      commit("setSituations_n1",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n1",error)
    })
  },
  getIdSituation_n1({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-1/"+data).then(response =>{
      commit("setSituation_id_n1",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n1",error)
    })
  },
  putSituation_n1({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"situation-niveau-1/"+data.id,data).then(() =>{
      commit("setSituation_n1","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n1",error)
    })
  },
  deleteSituation_n1({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"situation-niveau-1/"+data).then(() =>{
      commit("setSituation_n1","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n1",error)
    })
  },
  // Situation niveau 2
  postSituation_n2({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"situation-niveau-2",data).then(() =>{
      commit("setSituation_n2","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n2",error)
    })
  },
  getSituation_n2({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-2").then(response =>{
      commit("setSituations_n2",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n2",error)
    })
  },
  getIdSituation_n2({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-2/"+data).then(response =>{
      commit("setSituation_id_n2",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n2",error)
    })
  },
  putSituation_n2({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"situation-niveau-2/"+data.id,data).then(() =>{
      commit("setSituation_n2","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n2",error)
    })
  },
  deleteSituation_n2({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"situation-niveau-2/"+data).then(() =>{
      commit("setSituation_n2","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n2",error)
    })
  },
  // Situation niveau 3
  postSituation_n3({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"situation-niveau-3",data).then(() =>{
      commit("setSituation_n3","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n3",error)
    })
  },
  getSituation_n3({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-3").then(response =>{
      commit("setSituations_n3",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n3",error)
    })
  },
  getIdSituation_n3({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-3/"+data).then(response =>{
      commit("setSituation_id_n3",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n3",error)
    })
  },
  putSituation_n3({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"situation-niveau-3/"+data.id,data).then(() =>{
      commit("setSituation_n3","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n3",error)
    })
  },
  deleteSituation_n3({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"situation-niveau-3/"+data).then(() =>{
      commit("setSituation_n3","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n3",error)
    })
  },
  // Situation niveau 4
  postSituation_n4({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"situation-niveau-4",data).then(() =>{
      commit("setSituation_n4","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n4",error)
    })
  },
  getSituation_n4({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-4").then(response =>{
      commit("setSituations_n4",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n4",error)
    })
  },
  getIdSituation_n4({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"situation-niveau-4/"+data).then(response =>{
      commit("setSituation_id_n4",response.data)
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n4",error)
    })
  },
  putSituation_n4({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"situation-niveau-4/"+data.id,data).then(() =>{
      commit("setSituation_n4","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n4",error)
    })
  },
  deleteSituation_n4({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"situation-niveau-4/"+data).then(() =>{
      commit("setSituation_n4","Action effectuée avec succès")
    })
    .catch((error)=>{
      commit("setMsgFailSituations_n4",error)
    })
  },
}

const mutations = {
  setSituations_n1:(state,reponse) => (state.situations_n1 = reponse),
  setMsgFailSituations_n1:(state,reponse) => (state.msgFailSituation_n1 = reponse),
  setSituation_n1:(state,reponse) => (state.situation_n1 = reponse),
  setSituation_id_n1:(state,reponse) => (state.situation_id_n1 = reponse),
  setSituations_n2:(state,reponse) => (state.situations_n2 = reponse),
  setMsgFailSituations_n2:(state,reponse) => (state.msgFailSituation_n2 = reponse),
  setSituation_n2:(state,reponse) => (state.situation_n2 = reponse),
  setSituation_id_n2:(state,reponse) => (state.situation_id_n2 = reponse),
  setSituations_n3:(state,reponse) => (state.situations_n3 = reponse),
  setMsgFailSituations_n3:(state,reponse) => (state.msgFailSituation_n3 = reponse),
  setSituation_n3:(state,reponse) => (state.situation_n3 = reponse),
  setSituation_id_n3:(state,reponse) => (state.situation_id_n3 = reponse),
  setSituations_n4:(state,reponse) => (state.situations_n4 = reponse),
  setMsgFailSituations_n4:(state,reponse) => (state.msgFailSituation_n4 = reponse),
  setSituation_n4:(state,reponse) => (state.situation_n4 = reponse),
  setSituation_id_n4:(state,reponse) => (state.situation_id_n4 = reponse),
}

export default {
    state,
    getters,
    actions,
    mutations
}
