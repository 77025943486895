import HTTP from "./../../Api/auth"

const state = {
  syntheseGenerale: [],
  msgFailSyntheseGenerale:"",
  syntheseAnnuelle: [],
  msgFailSyntheseAnnuelle:"",
  remboursement: [],
  msgFailRemboursement:"",
  ficheTechnique: [],
  msgFailFicheTechnique:"",
}

const getters = {
  syntheseGenerale: state => state.syntheseGenerale,
  msgFailSyntheseGenerale: state => state.msgFailSyntheseGenerale,
  syntheseAnnuelle: state => state.syntheseAnnuelle,
  msgFailSyntheseAnnuelle: state => state.msgFailSyntheseAnnuelle,
  remboursement: state=>state.remboursement,
  msgFailRemboursement:state=>state.msgFailRemboursement,
  ficheTechnique: state=>state.ficheTechnique,
  msgFailFicheTechnique:state=>state.msgFailFicheTechnique,
}

const actions = {
  
  getSyntheseGenerale({ commit },data) {
    let query = "?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&type_etat=synthese_generale"
    HTTP.get(process.env.VUE_APP_AUTHURL + "etat-suivi-emprunts"+query)
      .then(response => {
        commit("setSyntheseGenerale", response.data)
        commit("setMsgFailSyntheseGenerale", "")
      })
      .catch(errors => {
        commit("setSyntheseGenerale", "")
        commit("setMsgFailSyntheseGenerale", errors)
      })
  },
  getRemboursement({ commit },data) {
    let query = "?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&annee="+data.annee+"&type_etat=remboursement_mensuel"
    HTTP.get(process.env.VUE_APP_AUTHURL + "etat-suivi-emprunts"+query)
      .then(response => {
        commit("setRemboursement", response.data)
        commit("setMsgFailRemboursement", "")
      })
      .catch(errors => {
        commit("setRemboursement", "")
        commit("setMsgFailRemboursement", errors)
      })
  },
  getSyntheseAnnuelle({ commit },data) {
    let query = "?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&annee="+data.annee+"&type_etat=synthese_annuelle"
    HTTP.get(process.env.VUE_APP_AUTHURL + "etat-suivi-emprunts"+query)
      .then(response => {
        commit("setSyntheseAnnuelle", response.data)
        commit("setMsgFailSyntheseAnnuelle", "")
      })
      .catch(errors => {
        commit("setSyntheseAnnuelle", "")
        commit("setMsgFailSyntheseAnnuelle", errors)
      })
  },
  getFicheTechnique({ commit },data) {
    let query = "?date_debut="+data.date_debut+"&date_fin="+data.date_fin+"&banque="+data.banque+"&montant_emprunt="+data.montant_emprunt+"&date_mise_place="+data.date_mise_place+"&annee_filtre="+data.annee_filtre
    HTTP.get(process.env.VUE_APP_AUTHURL + "fiche-technique-emprunts"+query)
      .then(response => {
        commit("setFicheTechnique", response.data)
        commit("setMsgFailFicheTechnique", "")
      })
      .catch(errors => {
        commit("setFicheTechnique", "")
        commit("setMsgFailFicheTechnique", errors)
      })
  },
  
}

const mutations = {
  setSyntheseGenerale: (state, syntheseGenerale) => (state.syntheseGenerale = syntheseGenerale),
  setMsgFailSyntheseGenerale: (state, msgFailSyntheseGenerale) => (state.msgFailSyntheseGenerale = msgFailSyntheseGenerale),
  setSyntheseAnnuelle: (state, syntheseAnnuelle) => (state.syntheseAnnuelle = syntheseAnnuelle),
  setMsgFailSyntheseAnnuelle: (state, msgFailSyntheseAnnuelle) => (state.msgFailSyntheseAnnuelle = msgFailSyntheseAnnuelle),
  setRemboursement: (state, remboursement) => (state.remboursement = remboursement),
  setMsgFailRemboursement: (state, msgFailRemboursement) => (state.msgFailRemboursement = msgFailRemboursement),
  setFicheTechnique: (state, ficheTechnique) => (state.ficheTechnique = ficheTechnique),
  setMsgFailFicheTechnique: (state, msgFailFicheTechnique) => (state.msgFailFicheTechnique = msgFailFicheTechnique),
}

export default {
  state,
  getters,
  actions,
  mutations
}
