import HTTP from "../../Api/auth"

const state ={
  planComptes:"",
  failPlanComptes:"",
  msgSuccessPlanCompte:"",
  msgFailPlanCompte:"",
  groupes:"",
  groupe:"",
  msgSuccessGroupe:"",
  msgFailGroupe:"",
  sousGroupes:"",
  sousGroupe:"",
  msgSuccessSousGroupe:"",
  msgFailSousGroupe:"",
}

const getters={
  planComptes:state => state.planComptes,
  failPlanComptes:state => state.failPlanComptes,
  msgSuccessPlanCompte:state => state.msgSuccessPlanCompte,
  msgFailPlanCompte:state => state.msgFailPlanCompte,
  groupes: state => state.groupes,
  groupe: state => state.groupe,
  msgSuccessGroupe: state => state.msgSuccessGroupe,
  msgFailGroupe: state => state.msgFailGroupe,
  sousGroupes: state => state.sousGroupes,
  sousGroupe: state => state.sousGroupe,
  msgSuccessSousGroupe: state => state.msgSuccessSousGroupe,
  msgFailSousGroupe: state => state.msgFailSousGroupe,
}

const actions = {
  getListPlanComptes({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL +"plan-comptes").then(response => {
     commit("setPlanComptes",response.data)
    }).catch(error => {
     commit("setFailPlanComptes",error.response.data.erreurs)
    })
  },
  postPlanCompte({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"plan-comptes", data).then(response => {
     commit("setMsgSuccessPlanCompte",response.data)
    }).catch(error => {
     commit("setMsgFailPlanCompte",error.response.data.erreurs)
    })
  },
  putPlanCompte({commit}, data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"plan-comptes/"+data.id, data).then(response => {
     commit("setMsgSuccessPlanCompte",response.data)
    }).catch(error => {
     commit("setMsgFailPlanCompte",error.response.data.erreurs)
    })
  },
  getListGroupes({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL +"etats-financier-groupes").then(response => {
     commit("setGroupes",response.data)
    }).catch(error => {
     commit("setFailGroupes",error.response.data.erreurs)
    })
  },
  postGroupe({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"etats-financier-groupes", data).then(response => {
     commit("setMsgSuccessGroupe",response.data)
    }).catch(error => {
     commit("setMsgFailGroupe",error.response.data.erreurs)
    })
  },
  putGroupe({commit}, data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"etats-financier-groupes/"+data.id, data).then(response => {
     commit("setMsgSuccessGroupe",response.data)
    }).catch(error => {
     commit("setMsgFailGroupe",error.response.data.erreurs)
    })
  },
  getListSousGroupes({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"etats-financier-groupes/"+data+"/etats-financier-sous-groupes").then(response => {
     commit("setSousGroupes",response.data)
    }).catch(error => {
     commit("setFailSousGroupes",error.response.data.erreurs)
    })
  },
  getSousGroupe({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"etats-financier-groupes/"+data.etat_financier_groupes+"/etats-financier-sous-groupes/"+data.id).then(response => {
     commit("setSousGroupe",response.data)
    }).catch(error => {
     commit("setFailSousGroupe",error.response.data.erreurs)
    })
  },
  postSousGroupe({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"etats-financier-groupes/"+data.etat_financier_groupes+"/etats-financier-sous-groupes", data).then(response => {
     commit("setMsgSuccessSousGroupe",response.data)
    }).catch(error => {
     commit("setMsgFailSousGroupe",error.response.data.erreurs)
    })
  },
  putSousGroupe({commit}, data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"etats-financier-groupes/"+data.etat_financier_groupes+"/etats-financier-sous-groupes/"+data.id, data).then(response => {
     commit("setMsgSuccessSousGroupe",response.data)
    }).catch(error => {
     commit("setMsgFailSousGroupe",error.response.data.erreurs)
    })
  }
}

const mutations = {
  setPlanComptes:(state, comptabilitetransaction) => (state.planComptes=comptabilitetransaction),
  setFailPlanComptes:(state, failcomptabilitetransaction) => (state.failPlanComptes=failcomptabilitetransaction),
  setMsgSuccessPlanCompte:(state, msgsuccesscomptabilitetransaction ) => (state.msgSuccessPlanCompte=msgsuccesscomptabilitetransaction),
  setMsgFailPlanCompte:(state, msgfailcomptabilitetransaction) => (state.msgFailPlanCompte=msgfailcomptabilitetransaction),
  setGroupes:(state, msgfailcomptabilitetransaction) => (state.groupes=msgfailcomptabilitetransaction),
  setGroupe:(state, msgfailcomptabilitetransaction) => (state.groupe=msgfailcomptabilitetransaction),
  setMsgSuccessGroupe:(state, msgfailcomptabilitetransaction) => (state.msgSuccessGroupe=msgfailcomptabilitetransaction),
  setMsgFailGroupe:(state, msgfailcomptabilitetransaction) => (state.msgFailGroupe=msgfailcomptabilitetransaction),
  setSousGroupes:(state, msgfailcomptabilitetransaction) => (state.sousGroupes=msgfailcomptabilitetransaction),
  setSousGroupe:(state, msgfailcomptabilitetransaction) => (state.sousGroupe=msgfailcomptabilitetransaction),
  setMsgSuccessSousGroupe:(state, msgfailcomptabilitetransaction) => (state.msgSuccessSousGroupe=msgfailcomptabilitetransaction),
  setMsgFailSousGroupe:(state, msgfailcomptabilitetransaction) => (state.msgFailSousGroupe=msgfailcomptabilitetransaction),
}

export default {
  state,
  getters,
  actions,
  mutations
}