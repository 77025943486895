import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueExcelEditor from 'vue-excel-editor'
import "jquery/src/jquery.js"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/custom.css"
import "./assets/css/theme.css"
import "./assets/fonts/flaticon.css"

new Vue({
  router,
  store,
  VueExcelEditor,
  render: h => h(App)
}).$mount("#app")
