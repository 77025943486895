import HTTP from "../../Api/auth"

const state={
  banques:"",
  banqueSansPage:"",
  msgSuccessBanque:"",
  msgFailBanque:"",
  msgFailBanqueSP:"",
}

const getters={
  banques: state => state.banques,
  banqueSansPage: state => state.banqueSansPage,
  msgSuccessBanque: state => state.msgSuccessBanque,
  msgFailBanque: state => state.msgFailBanque,
  msgFailBanqueSP: state => state.msgFailBanqueSP,
}

const actions={
  loadBanques({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL +"banques")
      .then((response) => {
        commit("setBanques",response.data)
      })
      .catch((error)=>{
        commit("setMsgFailBanque",error.response.data.code_message)
      })
  },
  listBanques({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL +"banques-sans-pagination")
      .then((response) => {
        commit("setBanqueSansPage",response.data)
      })
      .catch((error)=>{
        commit("setMsgFailBanqueSP",error.response.data.code_message)
      })
  },
  postBanques({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"banques",data)
      .then(() => {
        commit("setMsgSuccessBanque","Banque créée avec succès.")
      })
      .catch((error)=>{
        commit("setMsgFailBanque",error.response.data.code_message)
      })
  },
  putBanques({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"banques/"+data.id,data)
      .then(() => {
        commit("setMsgSuccessBanque","La banque a été modifiée")
      })
      .catch((error)=>{
        commit("setMsgFailBanque",error.response.data.code_message)
      })
  },
}

const mutations={
  setBanques:(state , banque) =>(state.banques = banque),
  setBanqueSansPage:(state , banque) =>(state.banqueSansPage = banque),
  setMsgSuccessBanque:(state , msgsuccessbanque) =>(state.msgSuccessBanque = msgsuccessbanque),
  setMsgFailBanque:(state , msgfailbanque) =>(state.msgFailBanques = msgfailbanque),
  setMsgFailBanqueSP:(state , msgfailbanquesp) =>(state.msgFailBanqueSP = msgfailbanquesp),
}

export default {
  state,
  getters,
  actions,
  mutations
}