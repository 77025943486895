import HTTP from "../../Api/auth"

const state = {
  tBoard: [],
  cBoard: [],
  msgSuccessExecution:"",
  msgFailExecution:"",
  errorBoard: ""
}

const getters = {
  tBoard: state => state.tBoard,
  cBoard: state => state.cBoard,
  msgSuccessExecution: state=>state.msgSuccessExecution,
  msgFailExecution: state=>state.msgFailExecution,
  errorBoard: state => state.errorBoard
}

const actions = {
  loadDashboardTable({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "recap-executions-budgetaires?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin)
      .then(response => {
        commit("setTBoard", response.data.donnees)
      })
      .catch(error => {
        commit("setEBoard", error)
        //console.log(error)
      })
  },
  loadDashboardChart({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "finances/recapitulatif?format=DIAGRAMME&annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin)
      .then(response => {
        commit("setCBoard", response.data.donnees)
      })
      .catch(error => {
        commit("setEBoard", error)
        //console.log(error)
      })
  },
  importExecutionBudgetaire({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-executions-budgetaires", data)
      .then(() => {
        commit("setMsgSuccessExecution", "Le fichier a bien été importé")
      })
      .catch((error) => {
        commit("setMsgFailExecution", error.response.data.erreurs)
      })
  }
}

const mutations = {
  setTBoard: (state, tboard) => (state.tBoard = tboard),
  setCBoard: (state, cboard) => (state.cBoard = cboard),
  setMsgSuccessExecution:(state, msgsuccessexecution) => (state.msgSuccessExecution = msgsuccessexecution),
  setMsgFailExecution:(state, msgfailexecution) => (state.msgFailExecution = msgfailexecution),
  setEBoard: (state, eBoard) => (state.errorBoard = eBoard)
}

export default {
  state,
  getters,
  actions,
  mutations
}
