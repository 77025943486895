import HTTP from "./../../Api/auth"
const state = {
  marches: [],
  singleMarches: "",
  updateMarches: "",
  marcherrors: "",
  marche: [],
  deleteMarches: "",
  marchpartenaires: [],
  msgSuccessImport:"",
  msgFailImport:"",
}

const getters = {
  marches: state => state.marches,
  singleMarches: state => state.singleMarches,
  updateMarches: state => state.updateMarches,
  marcherrors: state => state.marcherrors,
  marche: state => state.marche,
  deleteMarches: state => state.deleteMarches,
  marchpartenaires: state => state.marchpartenaires,
  msgSuccessImport: state => state.msgSuccessImport,
  msgFailImport: state => state.msgFailImport,
}
const actions = {
  Marches({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.filtres.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      count=1
      query+="]&proprietes=["
      data.proprietes.forEach(element => {
        if (count==1) {
          query+='"'+element+'"'
          count++
        }else{
          query+=',"'+element+'"'
        }      
      })
      query+="]"
      if (data.libelle) {
        query+="&libelle="+data.libelle
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "marches" + query)
      .then(response => {
        commit("setMarches", response.data)
        commit("setErors", "")
      })
      .catch((error) => {
        commit("setMarches", "")
        commit("setErors", error.response.data.code_message)
      })
  },
  ListMarches({ commit }) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "marches-sans-pagination")
      .then(response => {
        commit("setMarches", response.data)
        commit("setErors", "")
      })
      .catch((error) => {
        commit("setMarches", "")
        commit("setErors", error.response.data.code_message)
      })
  },
  pageMarches({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setMarches", response.data)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  async groupMarches({ commit }, data) {
    await HTTP.get(process.env.VUE_APP_AUTHURL + "marches", data)
      .then(response => {
        var march = []
        response.data.donnees.forEach(element => {
          if (element.prestataire.id == 1) {
            march.push(element)
          }
        })
        commit("setMarches", march)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  saveMarches({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "marches", data)
      .then(() => {
        commit("singleMarche", "Le marché a été créé avec succès")
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  updateMarche({ commit }, { data, id_marche }) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "marches/" + id_marche, data)
      .then(response => {
        commit("setupMarches", response.data.code_http)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  async getMarche({ commit }, data) {
    await HTTP.get(process.env.VUE_APP_AUTHURL + "marches/" + data)
      .then(response => {
        commit("setMarche", response.data.marche)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  async getPartenaire({ commit }) {
    await HTTP.get(process.env.VUE_APP_AUTHURL + "prestataires")
      .then(response => {
        commit("setPartenaires", response.data.donnees)
      })
      .catch((error) => {
        commit("setErors", error.response.data.code_message)
      })
  },
  delMarches({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"marches/"+data).then(() => {
      commit("deleteMarche", 'Le marché a bien été supprimé')
      commit("setErors", '')
    }).catch((error) => {
      commit("deleteMarche",'')
      commit("setErors", error.response.data.erreurs)
    })
  },
  importMarche({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-marches", data)
      .then(() => {
        commit("setMsgSuccessImport", "le fichier a bien été importé")
      })
      .catch((error) => {
        commit("setMsgFailImport", error.response.data.erreurs)
      })
  }
}
const mutations = {
  setMarches: (state, marches) => (state.marches = marches),
  setMarche: (state, marche) => (state.marche = marche),
  singleMarche: (state, singlemarches) => (state.singleMarches = singlemarches),
  setupMarches: (state, updatemarches) => (state.updateMarches = updatemarches),
  deleteMarche: (state, deletemarches) => (state.deleteMarches = deletemarches),
  setErors: (state, error) => (state.marcherrors = error),
  setPartenaires: (state, partenaires) => (state.marchpartenaires = partenaires),
  setMsgSuccessImport: (state, partenaires) => (state.msgSuccessImport = partenaires),
  setMsgFailImport: (state, partenaires) => (state.msgFailImport = partenaires),
}

export default {
  state,
  getters,
  actions,
  mutations
}
