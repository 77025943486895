import HTTP from "../../Api/auth"

const state ={
  listAccordsFinancement:[],
  msgSuccessAccordsFinancement:"",
  msgFailAccordsFinancement:"",
  accordFinancement:""
}

const getters={
  listAccordsFinancement: state => state.listAccordsFinancement,
  msgSuccessAccordsFinancement: state => state.msgSuccessAccordsFinancement,
  msgFailAccordsFinancement: state => state.msgFailAccordsFinancement,
  accordFinancement: state => state.accordFinancement
}

const actions = {
  getListAccordsFinancement({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"marches/"+data.id+"/accords-financements").then(response => {
     commit("setListAccordsFinancement",response.data)
    }).catch(error => {
     commit("setMsgFailAccordsFinancement",error)
    })
  },
  getAccordsFinancement({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/accords-financements/"+data.id).then(response => {
     commit("setAccordsFinancement",response.data)
    }).catch(error => {
     commit("setMsgFailAccordsFinancement",error)
    })
  },
  addAccordsFinancement({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/accords-financements",data).then(response => {
     commit("setMsgSuccessAccordsFinancement",response.data)
    }).catch(error => {
     commit("setMsgFailAccordsFinancement",error)
    })
  },
  updateAccordsFinancement({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/accords-financements/"+data.id, data).then(response => {
     commit("setMsgSuccessAccordsFinancement",response.data)
    }).catch(error => {
     commit("setMsgFailAccordsFinancement",error)
    })
  },
  deleteAccordsFinancement({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL +"marches/"+data.id_marche+"/accords-financements/"+data.id, data).then(response => {
     commit("setMsgSuccessAccordsFinancement",response.data)
    }).catch(error => {
     commit("setMsgFailAccordsFinancement",error)
    })
  },
}

const mutations = {
  setListAccordsFinancement: (state, listaccordFinancement) => (state.listAccordsFinancement = listaccordFinancement),
  setMsgSuccessAccordsFinancement: (state, msgsuccessaccordFinancement) => (state.msgSuccessAccordsFinancement = msgsuccessaccordFinancement),
  setMsgFailAccordsFinancement: (state, msgfailaccordFinancement) => (state.msgFailAccordsFinancement = msgfailaccordFinancement),
  setAccordsFinancement: (state, accordFinancements) => (state.accordFinancement = accordFinancements),
}

export default {
  state,
  getters,
  actions,
  mutations
}