import HTTP from "./../../Api/auth"
const state = {
  colonnes: [],
  colonnesFail: "",
  resultatFiltre:"",
  msgFailResultatFiltre:"",
}

const getters = {
  colonnes: state => state.colonnes,
  colonnesFail: state => state.colonnesFail,
  resultatFiltre: state =>state.resultatFiltre,
  msgFailResultatFiltre: state =>state.msgFailResultatFiltre,
}

const actions = {
  async loadColonnes({ commit }, data) {
    let query = "?tables=["
    var count=1
    data.forEach(element => {
      if (count==1) {
        query+='"'+element+'"'
        count++
      }else{
        query+=',"'+element+'"'

      }      
    })
    query+="]"
    await HTTP.get(process.env.VUE_APP_AUTHURL + "select-colonnes" + query)
      .then(response => {
        commit("setColonnes", response.data)
      })
      .catch(errors => {
        commit("setColonnesFail", errors.response.data.code_message)
      })
  },
  async loadFiltres({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.filtres.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      count=1
      query+="]&proprietes=["
      data.proprietes.forEach(element => {
        if (count==1) {
          query+='"'+element+'"'
          count++
        }else{
          query+=',"'+element+'"'
        }      
      })
      query+=']&module="'+data.module+'"'
    }
    await HTTP.get(process.env.VUE_APP_AUTHURL + "filtres" + query)
      .then(response => {
        commit("setResultatFiltre", response.data)
      })
      .catch(errors => {
        commit("setMsgFailResultatFiltre", errors.response.data.code_message)
      })
  }
}
const mutations = {
  setColonnes: (state, colonnes) => (state.colonnes = colonnes),
  setColonnesFail: (state, error) => (state.colonnesFail = error),
  setResultatFiltre:(state,reponse)=>(state.resultatFiltre= reponse),
  setMsgFailResultatFiltre:(state,reponse)=>(state.msgFailResultatFiltre= reponse),
}

export default {
  state,
  getters,
  actions,
  mutations
}
