import HTTP from "../../Api/auth"
const state = {
  decomptesP: [],
  decompterrorsP: "",
  successdecompteP: "",
  singleDP: "",
  dperrors: "",
  decompteP: [],
  updateDPmsg: "",
}

const getters = {
  decomptesP: state => state.decomptesP,
  decompterrorsP: state => state.decompterrorsP,
  successdecompteP: state => state.successdecompteP,
  singleDP: state => state.singleDP,
  dperrors: state => state.dperrors,
  decompteP: state => state.decompteP,
  updateDPmsg: state => state.updateDPmsg,
}

const actions = {
  DecomptesP({ commit }, data) {
    let query=""
    if (data!=null) {
      if( data.order !=null && data.libelle == null){
        query="?order=" + data.order + "&tri=" + data.tri  
      }else{
        query="?libelle="+data.libelle+"&order="+data.order+"&tri="+data.tri  
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes-partiels" + query)
      .then(response => {
        commit("setDecomptesP", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  saveDP({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "decomptes-partiels", data)
      .then(() => {
        commit("setSuccessP", "Le décompte a été enregistré avec succès")
        commit("setErrorP", "")
      })
      .catch(error => {
        commit("setSuccessP", "")
        commit("setErrorP", error.response.data.code_message)
      })
  },
  getDecompteP({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decomptes-partiels/"+ data)
      .then(response => {
        commit("setDP", response.data.decompte_partiel)
      })
      .catch((error) => {
        commit("setErrorsP", error.response.data.code_message)
      })
  },
  async updateDP({ commit }, { data, pid }) {
    await HTTP.put(process.env.VUE_APP_AUTHURL + "decomptes-partiels/" + pid, data)
      .then(() => {
        commit("updateDecompteP", "le Décompte a été mise à jour")
      })
      .catch((error) => {
        commit("setErrorsP", error.response.data.code_message)
      })
  },
}
const mutations = {
  setDecomptesP: (state, decompte) => (state.decomptesP = decompte),
  setSuccessP: (state, successMsg) => (state.successdecompteP = successMsg),
  updateDecompteP: (state, updatedecomptes) => (state.updateDPmsg = updatedecomptes),
  setErrorP: (state, error) => (state.decompterrorsP = error),
  setErrorsP: (state, error) => (state.dperrors = error),
  setDP: (state, decompte) => (state.singleDP = decompte),
}

export default {
  state,
  getters,
  actions,
  mutations
}
