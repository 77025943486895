import HTTP from './../../Api/auth'
const state = {
  missionControls: [],
  missionControl: [],
  msgSuccessMissionControl: "",
  msgErrorMissionControl: "",
  missionerrors:''
}

const getters = {
  missionControls: state => state.missionControls,
  missionControl: state => state.missionControl,
  msgSuccessMissionControl: state => state.msgSuccessMissionControl,
  msgErrorMissionControl: state => state.msgErrorMissionControl,
  missionerrors:state => state.missionerrors
}
const actions = {
  getMissionControls({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL+"mission-controles"+query).then((response) => {
      commit("setMissionControls", response.data)
    }).catch((error) => {
      commit("setMissionerrors", error.response.data.code_message)
    })
  },
  getMissionControl({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL+"mission-controles/"+data.id).then((response) => {
      commit("setMissionControl", response.data)
    }).catch((error) => {
      commit("setMissionerrors", error.response.data.code_message)
    })
  },
  postMissionControl({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL+"mission-controles",data).then((response) => {
      commit("setMsgSuccessMissionControl", response.data)
    }).catch((error) => {
      commit("setMsgErrorMissionControl", error.response.data.code_message)
    })
  },
  putMissionControl({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL+"mission-controles/"+data.id,data).then((response) => {
      commit("setMsgSuccessMissionControl", response.data)
    }).catch((error) => {
      commit("setMsgErrorMissionControl", error.response.data.code_message)
    })
  },
  deleteMissionControl({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL+"mission-controles/"+data.id, data).then((response) => {
      commit("setMsgSuccessMissionControl", response.data)
    }).catch((error) => {
      commit("setMsgErrorMissionControl", error.response.data.erreurs)
    })
  },
  pageMissions({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setMissionControls", response.data)
      })
      .catch((error) => {
        commit("setMissionerrors", error.response.data.code_message)
      })
  },

}
const mutations = {
  setMissionControls: (state, missioncontrols) => (
    state.missionControls = missioncontrols
  ),
  setMissionControl: (state, missioncontrol) => (
    state.missionControl = missioncontrol
  ),
  setMsgSuccessMissionControl: (state, msgsuccessmissionControl) => (
    state.msgSuccessMissionControl = msgsuccessmissionControl
  ),
  setMsgErrorMissionControl: (state, msgerrormissionControl) => (
    state.msgErrorMissionControl = msgerrormissionControl
  ),
  setMissionerrors: (state, missionerrors) => (
    state.missionerrors = missionerrors
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
