import HTTP from "./../../Api/auth"

const state = {
  transactions: [],
  transactionsId: [],
  transactionsCat: [],
  typeTransactions:"",
  graphTransactions:"",
  transaction: "",
  successTransaction: "",
  errorsTransaction: ""
}

const getters = {
  transactions: state => state.transactions,
  transactionsId: state => state.transactionsId,
  transactionsCat: state => state.transactionsCat,
  typeTransactions: state => state.typeTransactions,
  graphTransactions: state => state.graphTransactions,
  transaction: state => state.transaction,
  successTransaction: state => state.successTransaction,
  errorsTransaction: state => state.errorsTransaction
}

const actions = {
  loadTransactionsSC({ commit }, data) {
    let query=""
    if(data.tri == null){
      query="?en_attente=" + data.en_attente
    }else{
      query="?en_attente=" + data.en_attente + "&libelle=" + data.libelle + "&tri=" + data.tri + "&order=" +data.order
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "transactions" + query)
      .then(response => {
        commit("setTransactions", response.data)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  loadTransactions({ commit },data) {
    let query=""
    if(data != null){
      query="?libelle=" + data.libelle + "&tri=" + data.tri + "&order=" +data.order
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "transactions"+query)
      .then(response => {
        commit("setTransactionsCat", response.data)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  loadTransactionsId({ commit },data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "transactions/"+data)
      .then(response => {
        commit("setTransactionsId", response.data)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  pageTransactions({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setTransactions", response.data)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  pageTransactionsCat({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setTransactionsCat", response.data)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  updateTransaction({ commit },{id, data}) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "transactions/" + id, data)
      .then(response => {
        commit("setSucces", response.data.code_message)
      })
      .catch((error) => {
        commit("setErrors",error.response.data.code_message)
      })
  },
  loadTypeTransactions({commit}) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "transactions/types")
      .then(response => {
        commit("setTypeTransactions", response.data.donnees[0]) 
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  loadGraphTransactions({commit},data) {
    var query=""
    if(data.annee){
      query+="?annee="+data.annee
    }
    HTTP.post(process.env.VUE_APP_AUTHURL + "transactions/graphiques"+query)
      .then(response => {
        commit("setGraphTransactions", response.data) 
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  importCompte({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-comptes-bancaires",data)
      .then(() => {
        commit("setSucces", "Le fichier a bien été importé") 
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  }
}

const mutations = {
  setTransactions: (state, transactions) => (state.transactions = transactions),
  setTransactionsCat: (state, transactionsCat) => (state.transactionsCat = transactionsCat),
  setTransactionsId: (state, transactionsId) => (state.transactionsId = transactionsId),
  setTypeTransactions: (state, typetransactions) => (state.typeTransactions = typetransactions),
  setGraphTransactions: (state, graphtransactions) => (state.graphTransactions = graphtransactions),
  setTransaction: (state, transactions) => (state.transaction = transactions),
  setSucces: (state, successMsg) => (state.successTransaction = successMsg),
  setErrors: (state, errorsMsg) => (state.errorsTransaction = errorsMsg)
}

export default {
  state,
  getters,
  actions,
  mutations
}
