import HTTP from "../../Api/auth"

const state = {
  repHypothese: "",
  repInvestment: "",
  repBp: "",
  repPt: "",
  businessPlans: [],
  errorBp: "",
  errorPt: "",
  errorHp: "",
  pt:[],
  errorIm: ""
}

const getters = {
  repHypothese: state => state.repHypothese,
  repInvestment: state => state.repInvestment,
  repBp: state => state.repBp,
  repPt: state => state.repPt,
  businessPlans: state => state.businessPlans,
  errorBp: state => state.errorBp,
  errorPt: state => state.errorPt,
  errorHp: state => state.errorHp,
  pt: state => state.pt,
  errorIm: state => state.errorIm
}

const actions = {
  postHypothese({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/hypotheses",data)
      .then(response => {
        commit("setRepHp", response.data)
      })
      .catch(error => {
        commit("setErrorHp", error.response.data.code_message)
        //console.log(error)
      })
  },
  checkHypYear({ commit }){
    HTTP.get(process.env.VUE_APP_AUTHURL + "business-plans/projection-tarifs")
      .then(response => {
        commit("setPt", response.data)
      })
      .catch(error => {
        commit("setErrorPt", error)
        //console.log(error)
      })
  },
  postInvestment({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/investissements",data)
      .then(response => {
        commit("setRepIm", response.data)
      })
      .catch(error => {
        commit("setErrorIm", error)
        //console.log(error)
      })
  },
  postBusinessPlan({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans",data)
      .then(response => {
        commit("setRepBp", response.data)
      })
      .catch(error => {
        commit("setErrorBp", error.response.data.code_message)
      })
  },
  postProjectionTarif({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "business-plans/projection-tarifs", data)
      .then(response => {
        commit("setRepPt", response.data)
        console.log("Gros succès")
      })
      .catch((error) => {
        commit("setErrorPt", error.response.data.code_message)
        console.log(error.response.data.code_message)
        //console.log(error)
      })
  },
}

const mutations = {
  setRepHp: (state, rephp) => (state.repHypothese = rephp),
  setRepIm: (state, repim) => (state.repInvestment = repim),
  setRepBp: (state, repbp) => (state.repBp = repbp),
  setRepPt: (state, reppt) => (state.repPt = reppt),
  setPt: (state, reppt) => (state.pt = reppt),
  setErrorHp: (state, ehp) => (state.errorHp = ehp),
  setErrorIm: (state, eim) => (state.errorIm = eim),
  setErrorPt: (state, ept) => (state.errorPt = ept),
  setErrorBp: (state, ebp) => (state.errorBp = ebp)
}

export default {
  state,
  getters,
  actions,
  mutations
}
