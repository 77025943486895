import HTTP from "../../Api/auth"
const state = {
  decaissements: [],
  singleDecaissements: "",
  decaisserrors: "",
  decaissement: [],
  msgSuccessDecaissement:"",
  msgFailDecaissement:"",
  // dcmpsous_traitants: [],
  assignDecaissement: [],
  updateDecaissementmsg: ""
}

const getters = {
  decaissements: state => state.decaissements,
  singleDecaissements: state => state.singleDecaissements,
  decaisserrors: state => state.decaisserrors,
  decaissement: state => state.decaissement,
  msgSuccessDecaissement: state => state.msgSuccessDecaissement,
  msgFailDecaissement: state => state.msgFailDecaissement,
  // dcmpsous_traitants: state => state.dcmpsous_traitants,
  assignDecaissement: state => state.assignDecaissement,
  updateDecaissementmsg: state => state.updateDecaissementmsg
}
const actions = {
  Decaissements({ commit }, data) {
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decaissements"+query)
      .then(response => {
        commit("setDecaissements", response.data)
        //console.log(state.decaissements)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  valDecaissements({ commit }, data) {
    let query=""
    if (data!=null) {
      if (data.tri!=null) {
        query="?libelle="+data.libelle+"&order="+data.order+"&tri="+data.tri+"&en_attente="+data.en_attente  
      }else{
        query="?en_attente="+data.en_attente  
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "decaissements"+query)
      .then(response => {
        commit("setDecaissements", response.data)
        //console.log(state.decaissements)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  pageDecaissements({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setDecaissements", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  saveDecaissements({ commit }, data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "decaissements/"+ data.id_decompte, data)
      .then(() => {
        commit("setMsgSuccessDecaissement", "Décaissement créé")
        commit("setMsgFailDecaissement", "")
      })
      .catch((error) => {
        commit("setMsgSuccessDecaissement", "")
        commit("setMsgFailDecaissement", error.response.data.erreurs)
      })
  },
  getDecaissement({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + "decaissements/" + data)
      .then(response => {
        commit("setDecaisssment", response.data)
      })
      .catch((error) => {
        commit("setErrors", error.response.data.code_message)
      })
  },
  updateDecaissements({ commit }, data, pid) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "decaissements/" + pid, data)
      .then(response => {
        commit("updateDecaissement", "Decaissement is updated successfully, decaissement id is " + response.data.id)
        commit("setErrors", "")
      })
      .catch((error) => {
        commit("singleDecaissements", "")
        commit("setErrors", error.response.data.code_message)
      })
  }
}
const mutations = {
  setDecaissements: (state, decaissements) => (state.decaissements = decaissements),
  singleDecaissements: (state, singledecaissements) => (state.singleDecaissements = singledecaissements),
  updateDecaissement: (state, updatedecaissements) => (state.updateDecaissementmsg = updatedecaissements),
  setErrors: (state, error) => (state.decerrors = error),
  singleDecaissement: (state, error) => (state.decaissement = error),
  // setSous_traitants: (state, sous_traitants) => (state.dcmpsous_traitants = sous_traitants),
  setDecaissement: (state, decaissement) => (state.assignDecaissement = decaissement),
  setMsgSuccessDecaissement: (state, decaissement) => (state.msgSuccessDecaissement = decaissement),
  setMsgFailDecaissement: (state, decaissement) => (state.msgFailDecaissement = decaissement)
}

export default {
  state,
  getters,
  actions,
  mutations
}
