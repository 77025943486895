import HTTP from "../../Api/auth"

const state ={
  comptabiliteTransactions:"",
  failComptabiliteTransactions:"",
  msgSuccessComptabiliteTransaction:"",
  msgFailComptabiliteTransaction:"",

}

const getters={
  comptabiliteTransactions:state => state.comptabiliteTransactions,
  failComptabiliteTransactions:state => state.failComptabiliteTransactions,
  msgSuccessComptabiliteTransaction:state => state.msgSuccessComptabiliteTransaction,
  msgFailComptabiliteTransaction:state => state.msgFailComptabiliteTransaction,
}

const actions = {
  getListComptabiliteTransactions({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL +"comptabilite-globales"+query).then(response => {
     commit("setComptabiliteTransactions",response.data)
    }).catch(error => {
     commit("setFailComptabiliteTransactions",error.response.data.erreurs)
    })
  },
  postComptabiliteTransaction({commit}, data){
    HTTP.post(process.env.VUE_APP_AUTHURL +"comptabilite-globales", data).then(response => {
     commit("setMsgSuccessComptabiliteTransaction",response.data)
    }).catch(error => {
     commit("setMsgFailComptabiliteTransaction",error.response.data.erreurs)
    })
  },
  pageComptabiliteTransactions({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setComptabiliteTransactions", response.data)
      })
      .catch((error) => {
        commit("setFailComptabiliteTransactions",error.response.data.erreurs)
      })
  },
}

const mutations = {
  setComptabiliteTransactions:(state, comptabilitetransaction) => (state.comptabiliteTransactions=comptabilitetransaction),
  setFailComptabiliteTransactions:(state, failcomptabilitetransaction) => (state.failComptabiliteTransactions=failcomptabilitetransaction),
  setMsgSuccessComptabiliteTransaction:(state, msgsuccesscomptabilitetransaction ) => (state.msgSuccessComptabiliteTransaction=msgsuccesscomptabilitetransaction),
  setMsgFailComptabiliteTransaction:(state, msgfailcomptabilitetransaction) => (state.msgFailComptabiliteTransaction=msgfailcomptabilitetransaction),
}

export default {
  state,
  getters,
  actions,
  mutations
}