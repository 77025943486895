import HTTP from "../../Api/auth"

const state = {
  journalCG: [],
  bilanCG:"",
  resultatCG:"",
  msgSuccessJournalCG:"",
  msgSuccessAjustementCG:"",
  msgFailJournalCG: "",
  msgFailAjustementCG:"",
  msgFailBilanCG: "",
  msgFailResultatCG: "",
  errorJournalCG: "",
}

const getters = {
  journalCG: state => state.journalCG,
  bilanCG: state => state.bilanCG,
  resultatCG: state => state.resultatCG,
  msgSuccessJournalCG: state => state.msgSuccessJournalCG,
  msgSuccessAjustementCG: state => state.msgSuccessAjustementCG,
  msgFailJournalCG: state => state.msgFailJournalCG,
  msgFailAjustementCG: state => state.msgFailAjustementCG,
  msgFailBilanCG: state => state.msgFailBilanCG,
  errorJournalCG: state => state.errorJournalCG
}

const actions = {
  loadJournalCG({ commit },data) {
    var query=""
    var count=1
    var used=0
    if (data != null) {
      query+="?classe="+data.classe+"&annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&base="+data.base+"&periode="+data.periode
      if (data.filtre) {
        query+="&filtres=["  
        data.filtre.forEach(filtre => {
          if (filtre.type == "date") {
            if (filtre.operation!="" && filtre.valeur!="") {
              if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }else{
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }
            }
          }else{
            if (filtre.valeur!="" && filtre.nature!="") {
              if (count>1 && used !=0) {
                query+=","
                console.log(count)
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'",  "valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
              used+=1
            }
          }
          count+=1
        })
        query+="]"
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "liste-budgetaires"+query)
      .then(response => {
        commit("setJournalCG", response.data)
      })
      .catch(error => {
        commit("setErrorJournalCG", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadBilanCG({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "bilan-execution-budgetaire"+query)
      .then(response => {
        commit("setBilanCG", response.data)
      })
      .catch(error => {
        commit("setFailBilanCG", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadResultatCG({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "comptes-resultats-execution-budgetaire"+query)
      .then(response => {
        console.log(response.data)
        commit("setResultatCG", response.data.donnees)
      })
      .catch(error => {
        commit("setFailResultatCG", error.response.data.erreurs)
        //console.log(error)
      })
  },
  pageJournalCG({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setJournalCG", response.data)
      })
      .catch((error) => {
        commit("setErrorJournalCG", error.response.data.code_message)
      })
  },
  importerJournalCG({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-executions-budgetaires",data)
      .then(() => {
        commit("setMsgSuccessJournalCG", "Le fichier a bien été importé")
      })
      .catch(error => {
        commit("setFailJournalCG", error.response.data.erreurs)
        //console.log(error)
      })
  },
  ajusterJournalCG({ commit },data) {
    HTTP.put(process.env.VUE_APP_AUTHURL + "execution-budgetaire/"+data.id,data)
      .then(() => {
        commit("setMsgSuccessAjustementCG", "201")
      })
      .catch(error => {
        commit("ssetMsgFailAjustementCG", error.response.data.erreurs)
        //console.log(error)
      })
  },
}

const mutations = {
  setJournalCG: (state, plancomptablegeneral) => (state.journalCG = plancomptablegeneral),
  setBilanCG: (state, plancomptablegeneral) => (state.bilanCG = plancomptablegeneral),
  setResultatCG: (state, plancomptablegeneral) => (state.resultatCG = plancomptablegeneral),
  setMsgSuccessJournalCG: (state, saisieimporte) => (state.msgSuccessJournalCG = saisieimporte),
  setMsgSuccessAjustementCG: (state, saisieimporte) => (state.msgSuccessAjustementCG = saisieimporte),
  setFailJournalCG: (state, eSaisie) => (state.msgFailJournalCG = eSaisie),
  setMsgFailAjustementCG: (state, saisieimporte) => (state.msgFailAjustementCG = saisieimporte),
  setFailBilanCG: (state, eSaisie) => (state.msgFailBilanCG = eSaisie),
  setFailResultatCG: (state, eSaisie) => (state.msgFailResultatCG = eSaisie),
  setErrorJournalCG: (state, eplanmontant) => (state.errorJournalCG = eplanmontant)
}

export default {
  state,
  getters,
  actions,
  mutations
}
