import HTTP from "../../Api/auth"

const state = {
  journal: [],
  bilan:"",
  balance:"",
  analytique:"",
  resultat:"",
  planComptablePeage: [],
  planComtablePesage: [],
  planComtableSiege: [],
  SaisieOperation: [],
  msgSuccessJournal:"",
  msgFailJournal: "",
  msgFailBilan: "",
  msgFailBalance: "",
  msgFailAnalytique: "",
  msgFailResultat: "",
  errorJournal: "",
}

const getters = {
  journal: state => state.journal,
  bilan: state => state.bilan,
  balance: state => state.balance,
  analytique: state => state.analytique,
  resultat: state => state.resultat,
  planComtablePeage: state => state.planComptablePeage,
  planComtablePesage: state => state.planComptablePesage,
  planComtableSiege: state => state.planComptableSiege,
  SaisieOperation: state => state.SaisieOperation,
  msgSuccessJournal: state => state.msgSuccessJournal,
  msgFailJournal: state => state.msgFailJournal,
  msgFailBilan: state => state.msgFailBilan,
  msgFailBalance: state => state.msgFailBalance,
  msgFailAnalytique: state => state.msgFailAnalytique,
  errorJournal: state => state.errorJournal
}

const actions = {
  loadSaisieOperation({ commit },data) {
    var query=""
    if (data != null) {
      query+="?type_saisie="+data.type_saisie+"&type_comptabilite="+data.type_comptabilite+"&mouvement="+data.mouvement+"&annee="+data.annee
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "saisies-operations"+query)
      .then(response => {
        commit("setSaisieOperation", response.data.donnees)
      })
      .catch(error => {
        commit("setFailJournal", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadJournal({ commit },data) {
    var query=""
    var count=1
    var used=0
    if (data != null) {
      query+="?classe="+data.classe+"&annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&base="+data.base
      if (data.filtre) {
        query+="&filtres=["  
        data.filtre.forEach(filtre => {
          if (filtre.type == "date") {
            if (filtre.operation!="" && filtre.valeur!="") {
              if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }else{
                if (count>1 && used !=0) {
                  query+=","
                }
                query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
                used+=1
              }
            }
          }else{
            if (filtre.valeur!="" && filtre.nature!="") {
              if (count>1 && used !=0) {
                query+=","
                console.log(count)
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'",  "valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
              used+=1
            }
          }
          count+=1
        })
        query+="]"
      }
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "journaux"+query)
      .then(response => {
        commit("setJournal", response.data)
      })
      .catch(error => {
        commit("setErrorJournal", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadBilan({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&base="+data.base
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "bilan"+query)
      .then(response => {
        commit("setBilan", response.data)
      })
      .catch(error => {
        commit("setFailBilan", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadBalance({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&nombre_chiffre="+data.nombre_chiffre+"&base="+data.base+"&periode="+data.consultation
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "balances-commerciales"+query)
      .then(response => {
        commit("setBalance", response.data)
      })
      .catch(error => {
        commit("setFailBalance", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadAnalytique({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&base="+data.base
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "compta-analytiques"+query)
      .then(response => {
        commit("setAnalytique", response.data)
      })
      .catch(error => {
        commit("setFailAnalytique", error.response.data.erreurs)
        //console.log(error)
      })
  },
  loadResultat({ commit },data) {
    var query=""
    if (data != null) {
      query+="?annee_debut="+data.annee_debut+"&annee_fin="+data.annee_fin+"&base="+data.base
    }
    HTTP.get(process.env.VUE_APP_AUTHURL + "comptes-resultats"+query)
      .then(response => {
        commit("setResultat", response.data)
      })
      .catch(error => {
        commit("setFailResultat", error.response.data.erreurs)
        //console.log(error)
      })
  },
  pageJournal({ commit }, data) {
    HTTP.get(process.env.VUE_APP_AUTHURL + data)
      .then(response => {
        commit("setJournal", response.data)
      })
      .catch((error) => {
        commit("setErrorJournal", error.response.data.code_message)
      })
  },
  importerJournal({ commit },data) {
    HTTP.post(process.env.VUE_APP_AUTHURL + "importer-journaux",data)
      .then(() => {
        commit("setMsgSuccessJournal", "Le fichier a bien été importé")
      })
      .catch(error => {
        commit("setFailJournal", error.response.data.erreurs)
        console.log(error.response.data.erreurs)
      })
  },
}

const mutations = {
  setJournal: (state, plancomptablegeneral) => (state.journal = plancomptablegeneral),
  setBilan: (state, plancomptablegeneral) => (state.bilan = plancomptablegeneral),
  setBalance: (state, plancomptablegeneral) => (state.balance = plancomptablegeneral),
  setAnalytique: (state, plancomptablegeneral) => (state.analytique = plancomptablegeneral),
  setResultat: (state, plancomptablegeneral) => (state.resultat = plancomptablegeneral),
  setPlanComptablePeage: (state, plancomptablepeage) => (state.planComptablePeage = plancomptablepeage),
  setPlanComptablePesage: (state, plancomptablepesage) => (state.planComptablePesage = plancomptablepesage),
  setPlanComptableSiege: (state, plancomptablesiege) => (state.planComptableSiege = plancomptablesiege),
  setSaisieOperation: (state, saisieoperation) => (state.SaisieOperation = saisieoperation),
  setMsgSuccessJournal: (state, saisieimporte) => (state.msgSuccessJournal = saisieimporte),
  setFailJournal: (state, eSaisie) => (state.msgFailJournal = eSaisie),
  setFailBilan: (state, eSaisie) => (state.msgFailBilan = eSaisie),
  setFailBalance: (state, eSaisie) => (state.msgFailBalance = eSaisie),
  setFailAnalytique: (state, eSaisie) => (state.msgFailAnalytique = eSaisie),
  setFailResultat: (state, eSaisie) => (state.msgFailResultat = eSaisie),
  setErrorJournal: (state, eplanmontant) => (state.errorJournal = eplanmontant)
}

export default {
  state,
  getters,
  actions,
  mutations
}
